import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState } from 'react';
import WaitingPanel from '../WaitingPanel';

export default function CodingQuestion(){
    const windowState=[
        { name:'Basic Details'},
        { name:'Question Details'},
        { name:'Test cases'},
        { name:'Hidden Test cases'}
    ]
    const [isLoading,setLoading]=useState(false)
    const [Qseq,setQSeq]=useState(0)
    const [input,setInput]=useState('')
    const [output,setOutput]=useState('')
    const [weightage,setWeightage]=useState('0');
    const [hiddenTestcases,setHiddentTestCases]=useState([])
    const [currentState,setCurrentState]=useState(0)
    const [subjects,setSubject]=useState([])
    const [sampleTestCases,setSampleTestCases]=useState([])
    const [chapters,setChapter]=useState([])
    const goNext=async()=>{

        if(currentState===0){
            console.log(basicForm)
            if(basicForm.chapterName==='' || basicForm.companyTags===''||basicForm.difficulty===''
                ||basicForm.questionTitle===''||basicForm.subjectName===''){
                toast.error('Enter details !!')
                return
            }
            setCurrentState(currentState=>currentState+1)
        }
        else if(currentState===1){
            if(questionDescForm.constraints==='' || questionDescForm.inputFormat===''||questionDescForm.outputFormat===''||questionDescForm.desc===''){
                toast.error('Enter question details !!')
                return
            }
            setCurrentState(currentState=>currentState+1)
        }
        else if(currentState===2){
            if(sampleTestCasesElement.sampleInput===''||sampleTestCasesElement.sampleOutput===''||sampleTestCasesElement.explanation===''){
                toast.error('Enter details for sample test 1');
                return
            }
            if(sampleTestCasesElement1.sampleInput===''||sampleTestCasesElement1.sampleOutput===''||sampleTestCasesElement1.explanation===''){
                toast.error('Enter details for sample test 2');
                return
            }
            addTestCases()
            setCurrentState(currentState=>currentState+1)
        }
        else if(currentState===3){
            setLoading(true)
            const res = await fetch('https://api.leadsoftbackend.com/api/addCodingQuestion',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    questionTitle:basicForm.questionTitle,
                    level:basicForm.difficulty,
                    subjectName:basicForm.subjectName,
                    chapterName:basicForm.chapterName,
                    companyTags:basicForm.companyTags,
                    qSeq:Qseq,
                    sampleTestCase1:sampleTestCasesElement,
                    sampleTestCase2:sampleTestCasesElement1,
                    hiddenTestCasesArr:hiddenTestcases
                })
            })
            setLoading(false)
            const data = await res.json()
            console.log(data)
            if(data.status){
                setBasicForm({
                    questionTitle:'',difficulty:'',subjectName:'',chapterName:'',companyTags:''
                })
                setSampleTestCases([])
                setHiddentTestCases([])
                setCurrentState(0)
                toast.success(data.message)
            } else {
                toast.error(data.message)
            }
        }
        
    }
    const addTestCases = () => {
        setSampleTestCases(prevTestCases => [
            ...prevTestCases,
            setSampleTestCasesElement,
            setSampleTestCasesElement1
        ]);
    }
    const goPrevious=()=>{
        setCurrentState(currentState=>currentState-1)
    }
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],                   
        ['blockquote', 'code-block'],
        ['formula'],
      
        [{ 'header': 1 }, { 'header': 2 }],              
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],     
        [{ 'indent': '-1'}, { 'indent': '+1' }],                   
        
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      
        [{ 'color': [] }, { 'background': [] }],      
        [{ 'align': [] }],          
        ['clean']                                        
      ];
    const modules = {
        toolbar: toolbarOptions
    }
    const getQuestionSeq=async()=>{
        const res = await fetch('https://api.leadsoftbackend.com/api/getMaxSrNo/SELECT MAX(QSeq) AS ID from coding_question')
        const data = await res.json()
        console.log(data)
        if(data.status){
            if(data.message===null){
                setQSeq(1)
            }
            else {
                setQSeq(data.message+1)
            }
        }
    }
    const getSubjects=async()=>{
        const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM subjects')
        const data = await res.json()
        if(data.status){
            setSubject(data.message)
        }        
    }
    useEffect(()=>{
        getQuestionSeq()
        getSubjects()
    },[])
    const toolbarOptions1 = [
        ['bold', 'italic', 'underline', 'strike'],                   
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],             
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],      
        [{ 'align': [] }],          
        ['clean']                                        
      ];
    const modules1 = {
        toolbar: toolbarOptions1
    }
    const toolbarOptions2 = [        
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],      
        [{ 'align': [] }],          
        ['clean']                                        
      ];
    const modules2 = {
        toolbar: toolbarOptions2
    }
    
    const onHiddentTestSubmit=(e)=>{
        e.preventDefault()
        if(input==='' || output==='' || weightage==='0'){
            toast.error('Enter test cases details !!')
            return
        }
        toast.success('Test case added !!')
        setHiddentTestCases([
            ...hiddenTestcases,
            {input:input,output:output,weightage:weightage}
        ])
        setInput('')
        setOutput('')
        setWeightage('')
    }
    const removeItem=(index)=>{
        setHiddentTestCases([
            ...hiddenTestcases.slice(0, index),
            ...hiddenTestcases.slice(index + 1)
        ]);
        toast.success('Test cases deleted !!')
    }
    const [basicForm,setBasicForm]=useState({
        questionTitle:'',difficulty:'',subjectName:'',chapterName:'',companyTags:''
    })
    const handBasicChange=(e)=>{
        setBasicForm({
            ...basicForm,
            [e.target.name]:e.target.value
        })
    }
    const [questionDescForm,setQuestionDescForm]=useState({
        desc:'',inputFormat:'',outputFormat:'',constraints:''
    })
    const handleDesc=(val)=>{
        setQuestionDescForm({
            ...questionDescForm,
            desc:val
        })
    }
    const handleInputFormat=(val)=>{
        setQuestionDescForm({
            ...questionDescForm,
            inputFormat:val
        })
    }
    const handleOutputFormat=(val)=>{
        setQuestionDescForm({
            ...questionDescForm,
            outputFormat:val
        })
    }
    const handleConstraints=(val)=>{
        setQuestionDescForm({
            ...questionDescForm,
            constraints:val
        })
    }
    
    const [sampleTestCasesElement,setSampleTestCasesElement]=useState({
        sampleInput:'',sampleOutput:'',explanation:''
    })
    const handleSampleInput=(val)=>{
        setSampleTestCasesElement({
            ...sampleTestCasesElement,
            sampleInput:val
        })
    }
    const handleSampleOutput=(val)=>{
        setSampleTestCasesElement({
            ...sampleTestCasesElement,
            sampleOutput:val
        })
    }
    const handleExplanation=(val)=>{
        setSampleTestCasesElement({
            ...sampleTestCasesElement,
            explanation:val
        })
    }
    const [sampleTestCasesElement1,setSampleTestCasesElement1]=useState({
        sampleInput:'',sampleOutput:'',explanation:''
    })
    const handleSampleInput1=(val)=>{
        setSampleTestCasesElement1({
            ...sampleTestCasesElement1,
            sampleInput:val
        })
    }
    const handleSampleOutput1=(val)=>{
        setSampleTestCasesElement1({
            ...sampleTestCasesElement1,
            sampleOutput:val
        })
    }
    const handleExplanation1=(val)=>{
        setSampleTestCasesElement1({
            ...sampleTestCasesElement1,
            explanation:val
        })
    }
    const Chapter=async(e)=>{
        setBasicForm({
            ...basicForm,
            subjectName:e.target.value
        })
        
        const res = await fetch(`https://api.leadsoftbackend.com/api/getData/SELECT * from chapters WHERE SubjectName='${e.target.value}'`)
        const data = await res.json()
        if(data.status){
            setChapter(data.message)
        }
    }
    return(
        <>
            {
                isLoading &&
                <WaitingPanel/>
            }
            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container1">
                <div className="title">
                    <h2>{windowState[currentState] ? windowState[currentState].name:''}</h2>
                </div>            
                {
                    currentState===0 &&
                    <form>
                        <div className="input-field">
                            <label>Question Sequence</label>
                            <input type='number' value={Qseq} disabled/>
                        </div>
                        <div className="input-field">
                            <label>Question Title</label>
                            <input type='text' name='questionTitle' onChange={(e)=>handBasicChange(e)} value={basicForm.questionTitle}/>
                        </div>
                        <div className="input-field">
                            <label>Difficulty Level</label>
                            <select value={basicForm.difficulty} name='difficulty' onChange={(e)=>handBasicChange(e)}>
                                <option value=''>SELECT</option>
                                <option value='Easy'>Easy</option>
                                <option value='Meduim'>Medium</option>
                                <option value='Hard'>Hard</option>
                            </select>
                        </div>                        
                        <div className="input-field">
                            <label>Subject Name</label>
                            <select value={basicForm.subjectName} name='subjectName' onChange={(e)=>Chapter(e)}>
                                <option value=''>SELECT</option>
                                {
                                    subjects.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.Subjectname}>{item.SubjectName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-field">
                            <label>Chapter Name</label>
                            <select value={basicForm.chapterName} name='chapterName' onChange={(e)=>handBasicChange(e)}>
                                <option value=''>SELECT</option>
                                {
                                    chapters.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.ChapterName}>{item.ChapterName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-field">
                            <label>Company Tags</label>
                            <input type='text' placeholder='TCS,Wipro,Capgemini' value={basicForm.companyTags} name='companyTags' onChange={(e)=>handBasicChange(e)}/>
                        </div>
                    </form>
                }
                {
                    currentState==1 &&
                    <form>
                        <div className='input-field1'>
                            <label>Question Description</label>
                            <div className='question'>
                                <ReactQuill theme="snow"
                                modules={modules}   
                                onChange={handleDesc}
                                value={questionDescForm.desc}
                                style={{height:'150px'}}
                                />
                            </div>                                
                        </div>
                       
                        <div className='input-field2'>
                            <label>Input Format</label>
                            <div className='question'>
                                <ReactQuill theme="snow"
                                modules={modules1}
                                onChange={handleInputFormat}
                                value={questionDescForm.inputFormat}   
                                style={{height:'80px',backgroundColor:'white'}}
                                />
                            </div>                                
                        </div>
                        <div className='input-field2'>
                            <label>Output Format</label>
                            <div className='question'>
                                <ReactQuill theme="snow"
                                modules={modules1}
                                onChange={handleOutputFormat}
                                value={questionDescForm.outputFormat}   
                                style={{height:'80px'}}
                                />
                            </div>                                
                        </div>
                        <div className='input-field1'>
                            <label>Constraints</label>
                            <div className='question'>
                                <ReactQuill theme="snow"
                                modules={modules1}   
                                onChange={handleConstraints}
                                value={questionDescForm.constraints}
                                style={{height:'80px'}}
                                />
                            </div>                                
                        </div>
                    </form>
                }
                {
                    currentState===2 &&
                    <form>
                        <div className="input-field22">
                            <label>Sample Test Input 1</label>
                            <textarea
                                value={sampleTestCasesElement.sampleInput}
                                onChange={(e)=>handleSampleInput(e.target.value)}
                            ></textarea>
                        </div>
                        <div className="input-field22">
                            <label>Sample Test Output 1</label>
                            <textarea
                                value={sampleTestCasesElement.sampleOutput}
                                onChange={(e)=>handleSampleOutput(e.target.value)}
                            ></textarea>
                        </div>
                        <div className='input-field1'>
                            <label>Explanation</label>
                            <div className='question'>
                                <ReactQuill theme="snow"
                                modules={modules1}   
                                style={{height:'50px'}}
                                value={sampleTestCasesElement.explanation}
                                onChange={handleExplanation}
                                />
                            </div>                                
                        </div>
                        <div className="input-field22">
                            <label>Sample Test Input 2</label>
                            <textarea
                                value={sampleTestCasesElement1.sampleInput}
                                onChange={(e)=>handleSampleInput1(e.target.value)}
                            ></textarea>
                        </div>
                        <div className="input-field22">
                            <label>Sample Test Output 2</label>
                            <textarea
                                value={sampleTestCasesElement1.sampleOutput}
                                onChange={(e)=>handleSampleOutput1(e.target.value)}
                            ></textarea>
                        </div>
                        <div className='input-field1'>
                            <label>Explanation</label>
                            <div className='question'>
                                <ReactQuill theme="snow"
                                modules={modules1}   
                                style={{height:'50px'}}
                                value={sampleTestCasesElement1.explanation}
                                onChange={handleExplanation1}
                                />
                            </div>                                
                        </div>
                    </form>
                }
                {
                    currentState===3 &&
                    <div className='hidden'>
                        <form onSubmit={onHiddentTestSubmit}>
                            <div className="input-field1">
                                <label>Hidden Test Input </label>
                                <div className='question'>
                                    <ReactQuill theme="snow"                                        
                                        modules={modules2}   
                                        style={{height:'80px'}}
                                        value={input}
                                        onChange={setInput}
                                    />
                                </div>       
                            </div>
                            <div className="input-field1">
                                <label>Hidden Test Output </label>
                                <div className='question'>
                                    <ReactQuill theme="snow"                                        
                                        modules={modules2}   
                                        style={{height:'80px'}}
                                        value={output}
                                        onChange={setOutput}
                                    />
                                </div>       
                            </div>
                            <div className=' input-field22' style={{width:'94%'}}>
                                <label>Test Case Weightage(%)</label>
                                <input type='number' value={weightage} onChange={(e)=>setWeightage(e.target.value)}></input>
                            </div>
                            <input type='submit' value='Add Test Case'/>
                        </form>
                        <div className='test-cases'>
                            <div className="title">
                                <h1>Hidden Test Cases</h1>
                            </div>
                            {
                                hiddenTestcases.length > 0 ? (
                                    hiddenTestcases.map((item,key)=>{
                                        return(
                                            <>  
                                                <div className="box">
                                                    <div className="case" >                               
                                                        <div className="item" key={key}>
                                                            <label>Input</label>
                                                            <p dangerouslySetInnerHTML={{__html:item.input}}></p>                                    
                                                        </div>
                                                        <div className="item">
                                                            <label>Output</label>
                                                            <p dangerouslySetInnerHTML={{__html:item.output}}></p>                                    
                                                        </div>
                                                    </div>                                
                                                   <div className="case-1">
                                                        <div className="item">
                                                            <label>Weightage(%)</label>
                                                            <p>{item.weightage}</p>
                                                        </div>        
                                                        <div className="item">
                                                            <button onClick={()=>removeItem(key)}>Delete</button>
                                                        </div>
                                                    </div>         
                                                </div>
                                            </>                                       
                                            );
                                        }) 
                                    ) :(
                                        <p style={{width:'100%',padding:'10px', textAlign:'center',fontSize:'14px',color:'red'}}>No hidden test cases added !!</p>
                                    )
                            }
                        </div>
                    </div>                
                }
                <div className="progress-action">
                    <button onClick={()=>goPrevious()}>{windowState[currentState-1] ? 'Previous <- '+windowState[currentState-1].name:'<- Previous'} </button>
                    <button onClick={()=>goNext()}>{windowState[currentState+1] ? 'Next -> '+windowState[currentState+1].name:'Finish & Save'}</button>
                </div>
            </div>
        </>
    )
}