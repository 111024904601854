import { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CodingQuestion from './CodingQuestion';



export default function Questions(){

    const [single,setSingle]=useState(false)
    const [bulk,setBulk]=useState(false)
    const [view,setView]=useState(false)
    const [coding,setCoding]=useState(false)

    const handleClick=(arg)=>{
        if(arg==='single'){
            setSingle(true)
            setBulk(false)
            setView(false)
            setCoding(false)
        } else if(arg==='bulk'){
            setBulk(true)
            setSingle(false)
            setView(false)
            setCoding(false)
        } else if(arg==='view') {
            setBulk(false)
            setSingle(false)
            setView(true)
            setCoding(false)
        } else{
            setBulk(false)
            setSingle(false)
            setView(false)
            setCoding(true)
        }
    }

    function AddSingleQuestion(){
        const editor = useRef(null);
	    const [questionContent, setQuestionContent] = useState('');
        const stepper=['Basic Details','Question Details','Preview']
        const [currentState,setState]=useState(1)
        const [quetionForm,setQuestionForm]=useState({
            questionType:'',subjectName:'',chapterName:'',questionDifficulty:'',companyTags:'',
            questionContent:'',option1:'',option2:'',option3:'',option4:'',answer:''
        })
        const [subject,setSubject]=useState([])
        const [chapter,setChapter]=useState([])
        const getSubjects=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM subjects')
            const data = await res.json()
            if(data.status){
                setSubject(data.message)
            }
        }
        useEffect(()=>{
            getSubjects()
        },[])
        const handle=(e)=>{
            setQuestionForm({
                ...quetionForm,
                [e.target.name]:e.target.value
            })
        }
        const handleQuestion=(arg,value)=>{
            setQuestionForm({
                ...quetionForm,
                [arg]:value
            })
        }
        const nextState=()=>{
            if(currentState!=3){
                setState(currentState=>currentState+1)
            }
        }
        const previousState=()=>{
            if(currentState!=1){
                setState(currentState=>currentState-1)
            }
        }
       
        const setData=async()=>{
           if(currentState===1){
                if(quetionForm.chapterName===''||quetionForm.companyTags===''||quetionForm.questionDifficulty===''||quetionForm.subjectName===''){
                    toast.error('Select details !!')
                    return;
                }
                nextState()
           }
           else if(currentState===2){
                if(quetionForm.option1===''||quetionForm.option2===''||quetionForm.option3===''||
                    quetionForm.option4==='' || quetionForm.answer==='' || quetionForm.questionContent===''){
                    toast.error('Enter details !!')
                    return;
                }
                nextState()
                console.log(quetionForm)
           } else if(currentState==3){
                const res = await fetch('https://api.leadsoftbackend.com/api/addSingleQuestion',{
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json'
                    },
                    body:JSON.stringify(quetionForm)
                })
                const data = await res.json()
                if(data.status){
                    toast.success(data.message)
                    setQuestionForm({
                        questionType:'',subjectName:'',chapterName:'',questionDifficulty:'',companyTags:'',
                        questionContent:'',option1:'',option2:'',option3:'',option4:'',answer:''
                    })
                    setState(1)
                } else {
                    toast.error(data.message)
                }
           }
        }
        const handleSubject=async(e)=>{
            setQuestionForm({
                ...quetionForm,
                subjectName:e.target.value
            })
            let sub =e.target.value
            const res = await fetch("https://api.leadsoftbackend.com/api/getData/SELECT * FROM chapters WHERE SubjectName='"+sub+"'")
            const data = await res.json()
            if(data.status){
                setChapter(data.message)
            }
        }
        const setQuestionData=(e,editor)=>{
            const data = editor.getData();
            setQuestionContent(data)
        }
        const toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],                   
            ['blockquote', 'code-block'],
            ['formula'],
          
            [{ 'header': 1 }, { 'header': 2 }],              
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],     
            [{ 'indent': '-1'}, { 'indent': '+1' }],                   
            
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          
            [{ 'color': [] }, { 'background': [] }],      
            [{ 'align': [] }],          
            ['clean']                                        
          ];
        const modules = {
            toolbar: toolbarOptions
        }
          const optionsToolBar = [
            ['bold', 'italic', 'underline', 'strike'],                               
            ['formula'],
          
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'script': 'sub'}, { 'script': 'super' }],     
          
            [{ 'color': [] }, { 'background': [] }], 
            ['clean']                                        
          ];
          
        
        const optionModuels ={
            toolbar:optionsToolBar
        }
        
        const formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image'
          ]
        
        return(
            <>
                <div className='progress'>
                    {
                        stepper.map((item,index)=>{
                            return(
                                <div key={index} className={currentState >= index+1 ?'done':'step-item'}>
                                    <div className={currentState >= index+1?'active-circle':'circle'}>
                                        { currentState> index+1 ? <i className="fa-solid fa-check"></i> : index+1}
                                    </div>
                                </div>                                
                            )
                        })
                    }
                </div>
                <div className='container1'>
                    <div className='title'>
                        <h2>{stepper[currentState-1]}</h2>
                    </div>
                    {
                        currentState === 1 &&
                        <form>
                            <div className='input-field'>
                                <label>Question Type</label>
                                <select name='questionType' value={quetionForm.questionType} onChange={handle}>
                                    <option value=''>SELECT</option>
                                    <option value='Single Choice Question'>Single Choice Question</option>
                                    <option value='Multiple Choice Question'>Multiple Choice Question</option>
                                </select>
                            </div>
                            <div className='input-field'>
                                <label>Subject Name</label>
                                <select name='subjectName'  value={quetionForm.subjectName} onChange={handleSubject}>
                                    <option value=''>SELECT</option>
                                    {
                                        subject.length > 0 && subject.map((item,index)=>{
                                            return(
                                                <option key={index} value={item.SubjectName}>{item.SubjectName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='input-field'>
                                <label>Chapter Name</label>
                                <select name='chapterName'  value={quetionForm.chapterName} onChange={handle}>
                                    <option value=''>SELECT</option>
                                    {
                                        chapter.length > 0 && chapter.map((item,index)=>{
                                            return(
                                                <option key={index} value={item.ChapterName}>{item.ChapterName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='input-field'>
                                <label>Question Difficulty</label>
                                <select name='questionDifficulty' value={quetionForm.questionDifficulty} onChange={handle}>
                                    <option value=''>SELECT</option>
                                    <option value='Easy'>Easy</option>
                                    <option value='Meduim'>Meduim</option>
                                    <option value='Hard'>Hard</option>
                                </select>
                            </div>
                            <div className='input-field'>
                                <label>Company Tags</label>
                                <input type='text' name='companyTags' value={quetionForm.companyTags} onChange={handle}/>
                            </div>
                        </form>

                    }
                    {
                        currentState===2 &&
                        <form>
                           <div className='input-field1'>
                                <label>Question Title</label>
                                <div className='question'>
                                    <ReactQuill theme="snow"
                                    modules={modules}                                    
                                    value={quetionForm.questionContent} onChange={(value)=>handleQuestion('questionContent',value)}/>
                                </div>
                                
                           </div>
                           <div className='input-field2'>
                                <label>Option 1</label>
                                <div className='question'>
                                    <ReactQuill theme="snow"                                    
                                        modules={optionModuels}
                                        value={quetionForm.option1}  
                                        onChange={(value)=>handleQuestion('option1',value)}                                       
                                    />
                                </div>
                                
                           </div>
                           <div className='input-field2'>
                                <label>Option 2</label>
                                <div className='question'>
                                    <ReactQuill theme="snow"                                    
                                        modules={optionModuels}
                                        value={quetionForm.option2}    
                                        onChange={(value)=>handleQuestion('option2',value)}                                     
                                    />
                                </div>
                           </div>
                           <div className='input-field2'>
                                <label>Option 3</label>
                                <div className='question'>
                                    <ReactQuill theme="snow"                                    
                                        modules={optionModuels}
                                        value={quetionForm.option3}   
                                        onChange={(value)=>handleQuestion('option3',value)}                                      
                                    />
                                </div>
                           </div>
                           <div className='input-field2'>
                                <label>Option 4</label>
                                <div className='question'>
                                    <ReactQuill theme="snow"                                    
                                        modules={optionModuels}
                                        value={quetionForm.option4}    
                                        onChange={(value)=>handleQuestion('option4',value)}                                     
                                    />
                                </div>
                           </div>
                           <div className='input-field'>
                                <label>Answer</label>
                                <select name='answer' value={quetionForm.answer} onChange={handle}>
                                    <option value=''>SELECT</option>
                                    <option value='Option 1'>Option 1</option>
                                    <option value='Option 2'>Option 2</option>
                                    <option value='Option 3'>Option 3</option>
                                    <option value='Option 4'>Option 4</option>
                                </select>
                           </div>
                        </form>
                    }
                    {
                        currentState===3 && 
                        <div className='preview'>
                            <div className='details1'>
                                <p>{quetionForm.subjectName}</p>
                                <p>{quetionForm.chapterName}</p>
                            </div>
                            <div className='question'>
                                <p dangerouslySetInnerHTML={{__html:quetionForm.questionContent}}></p>
                            </div>
                            <div className='options'>
                                <div className={quetionForm.answer==='Option 1'?'correct-option':'option'}>
                                    <p dangerouslySetInnerHTML={{__html:quetionForm.option1}}/>
                                </div>
                                <div className={quetionForm.answer==='Option 2'?'correct-option':'option'}>
                                    <p dangerouslySetInnerHTML={{__html:quetionForm.option2}}/>
                                </div>
                                <div className={quetionForm.answer==='Option 3'?'correct-option':'option'}>
                                    <p dangerouslySetInnerHTML={{__html:quetionForm.option3}}/>
                                </div>
                                <div className={quetionForm.answer==='Option 4'?'correct-option':'option'}>
                                    <p dangerouslySetInnerHTML={{__html:quetionForm.option4}}/>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='progress-action'>
                        <button onClick={previousState}>Previous</button>
                        <button onClick={setData}>{currentState===3?'Finish':'Next'}</button>
                    </div>                    
                </div>
                
            </>
        )
    }

    function AddBulkQuestion(){
        const [chapter,setChapter]=useState([])
        const [subject,setSubject]=useState([])
        const getSubjects=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM subjects')
            const data = await res.json()
            if(data.status){
                setSubject(data.message)
            }
        }
        useEffect(()=>{
            getSubjects()
        },[])
        const [quetionForm,setQuestionForm]=useState({
            questionType:'',subjectName:'',chapterName:'',questionDifficulty:'',companyTags:'',
            questionContent:'',option1:'',option2:'',option3:'',option4:'',answer:''
        })
        const handle=(e)=>{
            setQuestionForm({
                ...quetionForm,
                [e.target.name]:e.target.value
            })
        }
        const handleSubject=async(e)=>{
            setQuestionForm({
                ...quetionForm,
                subjectName:e.target.value
            })
            let sub =e.target.value
            const res = await fetch("https://api.leadsoftbackend.com/api/getData/SELECT * FROM chapters WHERE SubjectName='"+sub+"'")
            const data = await res.json()
            if(data.status){
                setChapter(data.message)
            }
        }
        return(
            <>
                <div className='container1'>
                    <div className='title'>
                        <h2>Add Bulk Questions</h2>
                    </div>
                    <form>
                        <div className='input-field'>
                            <label>Question Type</label>
                            <select name='questionType' value={quetionForm.questionType} onChange={handle}>
                                <option value=''>SELECT</option>
                                <option value='Single Choice Question'>Single Choice Question</option>
                                <option value='Multiple Choice Question'>Multiple Choice Question</option>
                            </select>
                        </div>
                        <div className='input-field'>
                            <label>Subject Name</label>
                            <select name='subjectName'  value={quetionForm.subjectName} onChange={handleSubject}>
                                <option value=''>SELECT</option>
                                {
                                    subject.length > 0 && subject.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.SubjectName}>{item.SubjectName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='input-field'>
                            <label>Chapter Name</label>
                            <select name='chapterName'  value={quetionForm.chapterName} onChange={handle}>
                                <option value=''>SELECT</option>
                                {
                                    chapter.length > 0 && chapter.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.ChapterName}>{item.ChapterName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='input-field'>
                            <label>Question Difficulty</label>
                            <select name='questionDifficulty' value={quetionForm.questionDifficulty} onChange={handle}>
                                <option value=''>SELECT</option>
                                <option value='Easy'>Easy</option>
                                <option value='Meduim'>Meduim</option>
                                <option value='Hard'>Hard</option>
                            </select>
                        </div>
                        <div className='input-field'>
                            <label>Company Tags</label>
                            <input type='text' name='companyTags' value={quetionForm.companyTags} onChange={handle}/>
                        </div>
                        <div className='input-field'>
                            <label>Select Question File</label>
                            <input type='file' style={{'background':'white'}} accept='.xlsx,.xls'/>
                        </div>
                    </form>
                </div>
                
            </>
        )
    }

    function ViewQuestion(){
        return(
            <>
                <h1>View Question</h1>
            </>
        )
    }

    return(
        <>
        <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='panel'>
                <div className='header'>
                    <button onClick={()=>handleClick('single')} className={single ? 'activeButton':''} >Add Single Question</button>
                    <button onClick={()=>handleClick('bulk')} className={bulk ? 'activeButton':''}>Add Bulk Questions</button>
                    <button onClick={()=>handleClick('coding')} className={coding ? 'activeButton':''}>Add Coding Questions</button>
                    <button onClick={()=>handleClick('view')} className={view ? 'activeButton': ''}>View Questions</button>
                </div>
                <div className='panel2'>
                    {  single && <AddSingleQuestion/> }
                    {  bulk && <AddBulkQuestion/> }
                    {  view && <ViewQuestion/> }
                    {  coding && <CodingQuestion/>}
                </div>
            </div>
        </>
    )
}