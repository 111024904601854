import './style.css';
import WaitingPanel from '../WaitingPanel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
export default function Colleges(){

    const [addColg,setAddCollege]=useState(false)
    const [actionStatus,setActionStatus]=useState('Add College')
    const [viewColg,setViewCollege]=useState(false)
    const [isLoading,setLoading]=useState(false)
    const [editStatus,setEditStaus]=useState(false)
    const [editRecord,setEditRecord]=useState({})
    
    const actionClick=(arg)=>{
        if(arg=='Add'){
            setAddCollege(true)
            setViewCollege(false)
        } else {
            setViewCollege(true)
            setAddCollege(false)
            setActionStatus('Add College')
        }
    }


    function AddColleges(){
        const [colgForm,setColgForm]=useState({
            collegeId:0,collegeName:'',aliasName:'',colgAddress:'',principalName:'',principalContactNo:'',
            tpoName:'',tpoContactNo:'',username:'',password:''
        })
        const handle=(e)=>{
            setColgForm({
                ...colgForm,
                [e.target.name]:e.target.value
            })
        }
        const handleCollegeId=(val)=>{
            setColgForm({
                ...colgForm,
                collegeId:val
            })
        }
        const getCollegeId=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getMaxSrNo/SELECT MAX(SrNo) AS ID From colleges')
            const data = await res.json()
            if(data.status){
                if(data.message===null){
                    handleCollegeId(1)
                }
                else {
                    handleCollegeId(data.message+1)
                }
                
            }
        }
        useEffect(()=>{
            if(actionStatus==='Update College'){
                setColgForm({
                    collegeId:editRecord.SrNo,collegeName:editRecord.CollegeName,aliasName:editRecord.AliasName,
                    colgAddress:editRecord.CollegeAddress,principalName:editRecord.PrincipalName,principalContactNo:editRecord.PrincipalContactNo,
                    tpoName:editRecord.TPOName,tpoContactNo:editRecord.TPOContactNo,
                })
            } else {
                getCollegeId()
            }
        },[])
        const onValidate=()=>{
            if(colgForm.collegeName===''){
                toast.error('Enter college name')
                return false
            } else if(colgForm.aliasName===''){
                toast.error('Enter college alias name')
                return false
            } else if(colgForm.colgAddress===''){
                toast.error('Enter college address')
                return false
            } else if(colgForm.principalName===''){
                toast.error('Enter principal name')
                return false
            } else if(colgForm.principalContactNo===''){
                toast.error('Enter principal contact no')
                return false
            } else if(colgForm.principalContactNo.length!=10) {
                toast.error('Enter valid principal contact no')
                return false
            } else if(colgForm.tpoName===''){
                toast.error('Enter tpo name')
                return false
            } else if(colgForm.tpoContactNo===''){
                toast.error('Enter tpo contact no')
                return false
            } else if(colgForm.tpoContactNo.length!=10) {
                toast.error('Enter valid tpo contact no')
                return false
            } else if(colgForm.username===''){
                toast.error('Enter college username')
                return false
            } else if(colgForm.password===''){
                toast.error('Enter password')
                return false
            } else {
                return true;
            }
        }
        const onCollegeSubmit=async(e)=>{
            e.preventDefault()
            let check = onValidate()
            if(check){
                if(actionStatus==='Add College'){
                    setLoading(true)
                    const res = await fetch('https://api.leadsoftbackend.com/api/addCollege',{
                        method:'POST',
                        headers:{
                            'Content-Type':'application/json'
                        },
                        body:JSON.stringify(colgForm)
                    })
                    const data = await res.json()
                    setLoading(false)
                    if(data.status){
                        toast.success(data.message)
                        setColgForm({
                            collegeId:0,collegeName:'',aliasName:'',colgAddress:'',principalName:'',principalContactNo:'',
                            tpoName:'',tpoContactNo:'',username:'',password:''
                        })
                        getCollegeId()
                    } else {
                        toast.error(data.message)
                    }
                } else if(actionStatus==='Update College'){
                    setLoading(true)
                    const res = await fetch('https://api.leadsoftbackend.com/api/updateCollege',{
                        method:'POST',
                        headers:{
                            'Content-Type':'application/json'
                        },
                        body:JSON.stringify(colgForm)
                    })
                    const data = await res.json()
                    setLoading(false)
                    if(data.status){
                        toast.success(data.message)
                        setColgForm({
                            collegeId:0,collegeName:'',aliasName:'',colgAddress:'',principalName:'',principalContactNo:'',
                            tpoName:'',tpoContactNo:'',username:'',password:''
                        })
                        setActionStatus('Add College')
                        getCollegeId()
                    } else {
                        toast.error(data.message)
                    }
                }
            }
        }
        return(
            <>
                <div className='container1'>
                    <div className='title'>
                        <h2>{actionStatus}</h2>
                    </div>
                    <form onSubmit={onCollegeSubmit}>
                        <div className='input-field'>
                            <label>College Id</label>
                            <input type='number' value={colgForm.collegeId} disabled/>
                        </div>
                        <div className='input-field'>
                            <label>College Name</label>
                            <input type='text' name='collegeName' value={colgForm.collegeName} onChange={handle} />
                        </div>
                        <div className='input-field'>
                            <label>Alias Name</label>
                            <input type='text' name='aliasName' value={colgForm.aliasName} onChange={handle} />
                        </div>
                        <div className='input-field1'>
                            <label>College Address</label>
                            <textarea style={{'resize':'none'}} name='colgAddress' value={colgForm.colgAddress} onChange={handle}/>
                        </div>
                        <div className='input-field'>
                            <label>Principal Name</label>
                            <input type='text' name='principalName' value={colgForm.principalName} onChange={handle} />
                        </div>
                        <div className='input-field'>
                            <label>Principal Contact No</label>
                            <input type='number' name='principalContactNo' value={colgForm.principalContactNo} onChange={handle} />
                        </div>
                        <div className='input-field'>
                            <label>TPO Name</label>
                            <input type='text' name='tpoName' value={colgForm.tpoName} onChange={handle} />
                        </div>
                        <div className='input-field'>
                            <label>TPO Contact No</label>
                            <input type='number' name='tpoContactNo' value={colgForm.tpoContactNo} onChange={handle} />
                        </div>
                        { actionStatus!=='Update College' && <div className='input-field'>
                            <label>Username</label>
                            <input type='text' name='username' value={colgForm.username} onChange={handle} />
                        </div>}
                       { actionStatus!=='Update College' && <div className='input-field'>
                            <label>Password</label>
                            <input type='text' name='password' value={colgForm.password} onChange={handle} />
                        </div>}
                        <div className="actions">
                            <input type='submit' value={actionStatus}></input>
                            <input type='reset' value='Cancel'></input>
                        </div>
                    </form>
                </div>
            </>
        )
    }

    function ViewColleges(){
        const [colleges,setColleges]=useState([])
        const itemPerPage=10
        const [currentPage,setCurrentPage]=useState(1)
        const totalPages = Math.ceil(colleges.length/itemPerPage)
        const currentPageData = colleges.slice((currentPage-1)*itemPerPage,currentPage*itemPerPage)
        const getAllColleges=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM colleges')
            const data = await res.json()
            if(data.status){
                setColleges(data.message)
            }
        }
        useEffect(()=>{
            getAllColleges()
           
        },[])
        const collegeEdit=(colg)=>{
            actionClick('Add')
            setActionStatus('Update College')
            setEditStaus(true)
            setEditRecord(colg)
        }
        const deleteCollge=async(id)=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/deleteQuery',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    sql:`DELETE FROM colleges WHERE SrNo=?`,
                    id:id
                })
            })
            const data = await res.json()
            if(data.status){
                if(data.message==='deleted'){
                    toast.success('College deleted !!')
                    getAllColleges()
                }                
            } else {
                toast.error(data.message)
            }

        }
        return(
            <>
                <div className='container1'>
                    <div className='title'>
                        <h2>View Colleges</h2>
                    </div>                    
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>College Name</th>
                                <th>Alias Name</th>
                                <th>TPO Name</th>
                                <th>TPO Contact No</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentPageData.length > 0 ? (currentPageData.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{item.SrNo}</td>
                                            <td>{item.CollegeName}</td>
                                            <td>{item.AliasName}</td>
                                            <td>{item.TPOName}</td>
                                            <td>{item.TPOContactNo}</td>
                                            <td className="table-action">
                                                <button onClick={()=>collegeEdit(item)} ><i className="fa-solid fa-pen"></i></button>
                                                <button onClick={()=>deleteCollge(item.SrNo)}><i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })) : (
                                    <tr>
                                        <td colSpan="5">No colleges available</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <div className="pagination">
                        <p>{currentPage} of {totalPages}</p>
                        <div className="buttons">
                        {
                            Array.from({length:totalPages},(_,i)=>i+1).map((page,index)=>{
                                return(
                                    <button onClick={()=>setCurrentPage(page)} className={currentPage===page ? 'selectedButton':''} key={index}>{page}</button>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </>
        )   
    }

    return(
        <>
            { isLoading && <WaitingPanel/>}
            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='panel'>
                <div className='header'>
                    <button className={addColg ? 'activeButton':''} onClick={()=>actionClick('Add')}>{actionStatus}</button>
                    <button className={viewColg ? 'activeButton':''} onClick={()=>actionClick('view')}>View Colleges</button>
                </div>
                <div className='panel2'>
                    {
                        addColg &&
                        <AddColleges/>
                        
                    }
                    {
                        viewColg &&
                        <ViewColleges/>
                    }
                </div>
            </div>
        </>
    )
}