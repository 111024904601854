import { useEffect, useState } from "react"
import WaitingPanel from '../WaitingPanel';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
import './style.css';

export default function Chapters(){
    const [addChapter,setAddChapter]=useState(false)
    const [viewChpater,setViewChapter]=useState(false)
    
    
    function AddChapter(){        
        const [isLoading,setLoading]=useState(false)
        const [uniqueId,setUniqueId]=useState(0)
        const [chapterName,setChapterName]=useState('')
        const [subjectName,setSubjectName]=useState('')        
        const [subjects,setSubjects]=useState([])

        const getAllSubjects=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getAllSubjects')
            const data= await res.json()
            if(data.status){
                setSubjects(subjects=>data.message)
            }
        }
        const getMaxSrNo=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getMaxSrNo/'+'SELECT MAX(SRNO) AS ID from chapters')
            const data= await res.json()
            if(data.status){
                setUniqueId(data.message+1)
            }
        }
       
        const addChapters=async(e)=>{
            e.preventDefault()
            if(subjectName==='' || chapterName===''){
                toast.error('Enter all fields !!')
                return
            }
            if(actionStatus==='Add Chapter'){
                setLoading(true)
                const res = await fetch('https://api.leadsoftbackend.com/api/addChapter',{
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json'
                    },
                    body:JSON.stringify({
                        chapterName:chapterName,
                        subjectName:subjectName
                    })
                })
                const data = await res.json()
                setLoading(false)
                if(data.status){
                    toast.success(data.message)
                    setChapterName('')
                    setSubjectName('')
                    getMaxSrNo()
                    
                } else {
                    toast.error(data.message)
                }
            } else if(actionStatus==='Edit Chapter'){
                setLoading(true)
                const res = await fetch('https://api.leadsoftbackend.com/api/updateChapter',{
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json'
                    },
                    body:JSON.stringify({
                        chapterName:chapterName,
                        subjectName:subjectName,
                        srno:uniqueId
                    })
                })
                const data = await res.json()
                setLoading(false)
                if(data.status){
                    toast.success(data.message)
                    setChapterName('')
                    setSubjectName('')
                    getMaxSrNo()
                    setStatus('Add Chapter')
                    
                } else {
                    toast.error(data.message)
                }
            }
        }
        const resetForm=()=>{
            setStatus('Add Chapter')
            getMaxSrNo()
        }
        useEffect(()=>{                        
            if(actionStatus==='Edit Chapter'){
                setChapterName(editData.chapterName)
                setUniqueId(editData.SrNo)
                setSubjectName(editData.subjectName)
            } else {
                getMaxSrNo()
            }
        },[])
        useEffect(()=>{
            getAllSubjects()            
        },[])
        return(
            <>
                {
                    isLoading &&
                    <WaitingPanel/>
                }
                
                <div className="container1">
                    <div className="title">
                        <h2>{actionStatus}</h2>
                    </div>
                    <form onSubmit={addChapters} onReset={resetForm}>
                        <div className="input-field">
                            <label>Serial No</label>
                            <input type='number' value={uniqueId} disabled/>
                        </div>
                        <div className="input-field">
                            <label>Subject Name</label>
                            <select value={subjectName} onChange={(e)=>setSubjectName(e.target.value)}>
                                <option value=''>SELECT</option>
                                {
                                    subjects.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.SubjectName}>{item.SubjectName}</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-field">
                            <label>Chapter Name</label>
                            <input type='text' value={chapterName} onChange={(e)=>setChapterName(e.target.value)} />
                        </div>
                        <div className="actions">
                            <input type='submit' value={actionStatus}></input>
                            <input type='reset' value='Cancel'></input>
                        </div>
                    </form>
                </div>
            </>
        )
    }
    function ViewChapter(){        
        const [isLoading,setLoading]=useState(false)
        const [chapters,setChapters]=useState([])
        const itemsPerPage=10
        const [currentPage,setCurrentPage]=useState(1)
        const totalPages = Math.ceil(chapters.length/itemsPerPage)

        const currentPageData=chapters.slice((currentPage-1)*itemsPerPage,currentPage*itemsPerPage)
        const getAllChapters=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getAllChapters')
            const data= await res.json()
            if(data.status){
                setChapters(data.message)
            }
        }
        const deleteChapter=async(srno)=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/deleteChapter/'+srno,{
                method:'DELETE',
                headers:{
                    'Content-Type':'application/json'
                }                
            })
            const data = await res.json()
            if(data.status){
                toast.success(data.message)
                getAllChapters()
                setCurrentPage(1)
            } else{
                toast.error(data.message)
            }
        }
        useEffect(()=>{
            getAllChapters()
            
        },[])
        return(
            <>
                {
                    isLoading &&
                    <WaitingPanel/>
                }
                
                <div className="container1">
                    <div className="title">
                        <h2>View Chapter</h2>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Subject Name</th>
                            <th>Chapter Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentPageData.length > 0 ? currentPageData.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td>{item.SrNo}</td>
                                        <td>{item.SubjectName}</td>
                                        <td>{item.ChapterName}</td>
                                        <td className="table-action">
                                            <button onClick={()=>{setEdit('add',{chapterName:item.ChapterName,subjectName:item.SubjectName,SrNo:item.SrNo})}}><i className="fa-solid fa-pen"></i></button>
                                            <button onClick={()=>deleteChapter(item.SrNo)}><i className="fa-solid fa-trash"></i></button>
                                        </td>
                                    </tr>
                                )
                            }) :
                            (
                                <tr>
                                    <td colSpan="3">No Chapters avaialble !!</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <div className="pagination">
                    <p>{currentPage} of {totalPages}</p>
                    <div className="buttons">
                    {
                        Array.from({length:totalPages},(_,i)=>i+1).map((page,index)=>{
                            return(
                                <button onClick={()=>setCurrentPage(page)} className={currentPage===page ? 'selectedButton':''} key={index}>{page}</button>
                            )
                        })
                    }
                    </div>
                </div>
            </>
        )
    }
    const [actionStatus,setStatus]=useState('Add Chapter')

    const [editData,setEditData]=useState({
        SrNo:0,
        chapterName:'',
        subjectName:''
    })
    const setActive=(arg)=>{
        if(arg==='add'){
            setAddChapter(true)
            setViewChapter(false)
        } else {
            setViewChapter(true)
            setAddChapter(false)
        }
    }
    const setEdit=(arg,object)=>{
        if(arg==='add'){
            setAddChapter(true)
            setViewChapter(false)
        }
        setEditData(object)
        setStatus('Edit Chapter')
    }
    return(
        <>
            
            <div className='panel'>
                <div className='header'>
                    <button className={addChapter?'activeButton':''} onClick={()=>setActive('add')}>{actionStatus}</button>
                    <button className={viewChpater?'activeButton':''} onClick={()=>setActive('view')}>View Chapters</button>
                </div>
                <div className='panel2'>
                    {
                        addChapter &&
                        <AddChapter/>
                        
                    }
                    {
                        viewChpater &&
                        <ViewChapter/>
                    }
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
}