import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WaitingPanel from '../WaitingPanel';
import './style.css';
import { useEffect, useState } from 'react';

export default function Exams(){
    const [add,setAdd]=useState(false)
    const [view,setView]=useState(false)
    const [isLoading,setLoading]=useState(false)
    const [examId,setExamId]=useState(0)    
    
    const actionClick=(arg)=>{
        if(arg==='Add'){
            
            getExamId()
            setAdd(true)
            setView(false)
        } else {
            setView(true)
            setAdd(false)
        }
    }
    
    const getExamId=async()=>{
        const res = await fetch('https://api.leadsoftbackend.com/api/getMaxSrNo/SELECT MAX(SrNo) as ID FROM exams')
        const data = await res.json()
        if(data.status){
            if(data.message===null){
                setExamId(examId=>1)
            } else {
                setExamId(examId=>data.message+1)
            }
            
        }
    }
    useEffect(() => {
        const handleBeforeUnload = (event) => {
          const message = "Are you sure you want to leave? Your changes may not be saved.";
          event.returnValue = message;
          return message;
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);
    function AddExam({examId}){
        const stepper=['Basic Details','Section Details','Exam Assign']
        const [currentState,setState]=useState(1)
        const [sectionActionStatus,setSectionActionStatus]=useState('Add Section')                
        const [qLeft,setQLeft]=useState(0)
        const [DLeft,setDLeft]=useState(0)
        const [MLeft,setMLeft]=useState(0)
        
       
        let [card,setCard]=useState([])
        const [examForm,setExamForm]=useState({
            examId:examId,examName:'',examCategory:'',
            examFrom:'',examTo:'',totalDuration:0,totalMarks:0,noOfQuestions:0
        })
        const [sectionForm,setSectionForm]=useState({
            sectionName:'',questions:0,duration:0,
            marks:0,chapter:'',subjectName:'',examId:examForm.examId
        })
        useEffect(() => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
            setExamForm({
                ...examForm,
                examFrom:formattedDateTime,
                examTo:formattedDateTime
            })
          }, []);
        const getCategories=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM exam_category')
            const data = await res.json()            
            if(data.status){
                setExamCategory(data.message)
            }                    
        }
        useEffect(()=>{
            getCategories()
        },[])
        const handleExamId=(arg)=>{
            setExamForm({
                ...examForm,
                examId:arg
            })
        }
        const handle=(e)=>{            
            setExamForm({
                ...examForm,
                [e.target.name]:e.target.value
            })
        }
        const nextState=()=>{
            if(currentState!=3){
                setState(currentState=>currentState+1)
            }
        }
        const previousState=()=>{
            if(currentState!=1){
                setState(currentState=>currentState-1)
            }
        }
        const setData=async(e)=>{
            if(currentState===1){
                if(examForm.examCategory===''||examForm.examFrom===''||examForm.examName===''||examForm.examTo===''||examForm.totalDuration===0 || examForm.totalMarks==0 || examForm.noOfQuestions===0){
                    toast.error('Enter details !!')
                    return
                }
                if(examForm.examFrom===examForm.examTo){
                    toast.error('Dates must not be same !!')
                    return
                }
                if(card.length==0){
                    setDLeft(examForm.totalDuration)
                    setMLeft(examForm.totalMarks)
                    setQLeft(examForm.noOfQuestions)
                } else {
                    let dleft=0,mleft=0,qleft=0;
                    card.forEach(element => {
                        dleft+=parseInt(element.duration)
                        mleft+=parseInt(element.marks)
                        qleft+=parseInt(element.questions)
                    });
                    setDLeft(parseInt(examForm.totalDuration)-dleft)
                    setMLeft(parseInt(examForm.totalMarks)-mleft)
                    setQLeft(parseInt(examForm.noOfQuestions)-qleft)
                }
                setSectionForm({
                    ...sectionForm,
                    examId:examForm.examId
                })
                nextState()
            }
            else if(currentState===2){
                if(DLeft!==0  && MLeft!==0 && qLeft!==0){
                    toast.error('Sections not yet fullfilled !!')
                    return
                }
                getCategories()
                nextState()                
                hanleExamCategory(examForm.examCategory)
            }
            else if(currentState==3){                                
                let assignCount=setCount()
                if(assignCount===0){
                    toast.error('Assign batches !!')
                    return
                }
                try {

                
                    setLoading(true)
                    const res = await fetch('https://api.leadsoftbackend.com/api/addExamBasicDetails',{
                        method:'POST',
                        headers:{
                            'Content-Type':'application/json'
                        },
                        body:JSON.stringify(examForm)
                    })
                    const data = await res.json()
                    if(data.status){
                        const res1 = await fetch('https://api.leadsoftbackend.com/api/addExamSectionDetails',{
                            method:'POST',
                            headers:{
                                'Content-Type':'application/json'
                            },
                            body:JSON.stringify(card)
                        })
                        const data1 = await res1.json() 
                        if(data1.status)  {
                            const res2 = await fetch('https://api.leadsoftbackend.com/api/assignExam',{
                                method:'POST',
                                headers:{
                                    'Content-Type':'application/json'
                                },
                                body:JSON.stringify(assignTables)
                            })  
                            const data2 = await res2.json()
                            if(data2.status){
                                setLoading(false)
                                toast.success(data2.message)
                                setTimeout(() => {    
                                    getExamId()
                                    setExamForm({
                                        examId:examId,examName:'',examCategory:'',
                                        examFrom:'',examTo:'',totalDuration:0,totalMarks:0,
                                        noOfQuestions:0
                                    })                                
                                    actionClick('Add')                                    
                                }, 2700);
                            } 
                        }
                        
                    }
                }catch(error){
                    setLoading(false)
                    toast.error('Internal server error !!')
                }
            }
        }
        const setCount=()=>{
            let count=0            
            for (let index = 0; index < assignTables.length; index++) {
                const element = assignTables[index];                
                if(element.isAssign===true){
                    count+=1
                }
            }
            return count
        }
        
        const [chapters,setChapters]=useState([])
        const [subjects,setSubjects]=useState([])
        const [subjectName,setSubjectName]=useState('')
        const getSubjects=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM subjects')
            const data = await res.json()
            if(data.status){
                setSubjects(data.message)
            }
        }
        const getChapters=async(e)=>{
            setSubjectName(e.target.value)
            setSectionForm({
                ...sectionForm,
                subjectName:e.target.value
            })
            const res = await fetch("https://api.leadsoftbackend.com/api/getData/SELECT * FROM chapters WHERE SubjectName='"+e.target.value+"'")
            const data = await res.json()
            if(data.status){
                setChapters(data.message)
            }
        }
        useEffect(()=>{
            setDLeft(examForm.totalDuration)
            setMLeft(examForm.totalMarks)
            setQLeft(examForm.noOfQuestions)
            getSubjects()
        },[])
      
        const handleSection=(e)=>{            
            setSectionForm({
                ...sectionForm,
                examId:examForm.examId,
                [e.target.name]:e.target.value
            })            
        }
        const validateSectionForm=()=>{
            if(sectionForm.sectionName===''){
                toast.error('Enter section name !!')
                return false
            } else if(sectionForm.duration===0 || sectionForm.duration===''){
                toast.error('Enter section duration !!')
                return false
            } else if(sectionForm.marks===0  || sectionForm.marks===''){
                toast.error('Enter section marks !!')
                return false
            } else if(sectionForm.questions===0  || sectionForm.questions===''){
                toast.error('Enter no of questions !!')
                return false
            } else if(sectionForm.subjectName===''){
                toast.error('Select subject name !!')
                return false
            } else if(sectionForm.chapter===''){
                toast.error('Select Chapter Name !!')
                return false
            } else {
                return true
            }
        }
        const addSection=(e)=>{
            e.preventDefault()
            if(!validateSectionForm()){                
                return
            }            
            if(sectionActionStatus==='Add Section'){
                if (DLeft===0 || MLeft === 0 ||qLeft===0){
                   toast.error('All section packed !!')                    
                    return
                }
                else if(sectionForm.duration > DLeft){
                    toast.error('Duration exceed !!')
                    return
                } else if(sectionForm.questions > qLeft) {
                    toast.error('Question exceed !!')
                    return
                } else if(sectionForm.marks > MLeft){
                    toast.error('Marks exceed !!')
                    return;
                }
                card.push(sectionForm)
                let d_left=0,m_left=0,Q_left=0;
                d_left=DLeft-sectionForm.duration
                m_left=MLeft-sectionForm.marks
                Q_left=qLeft-sectionForm.questions
                setMLeft(m_left);setDLeft(d_left);setQLeft(Q_left)
                toast.success('Section added !!')
            } else if(sectionActionStatus==='Update Section'){

            }
            setSectionForm({
                sectionName:'',questions:0,duration:0,marks:0,chapter:'',subjectName:''
            })
            setSubjectName('')
        }
        const onSectionReset=()=>{
            setSectionForm({
                sectionName:'',questions:0,duration:0,marks:0,chapter:'',subjectName:''
            })
            setSubjectName('')
        }
        const deleteSection=(index,record)=>{
            const updatedItems = [...card];
            updatedItems.splice(index, 1);
            setCard(updatedItems)
            setDLeft(DLeft=>parseInt(DLeft)+parseInt(record.duration))
            setMLeft(MLeft=>parseInt(MLeft)+parseInt(record.marks))
            setQLeft(qLeft=>parseInt(qLeft)+parseInt(record.questions))
            toast.success('Section deleted !!')
        }
        const [categories,setExamCategory]=useState([])        
        const [assignTables,setAssignTables]=useState([])
        const hanleExamCategory=async(val)=>{            
            const res = await fetch("https://api.leadsoftbackend.com/api/getData/SELECT * FROM exam_category WHERE CategoryName='"+val+"'")
            const data = await res.json()
            if(data.status){                
                const updatedAssignTable = data.message.map(item=>({
                    ...item,
                    'isAssign':false,
                    'examId':examForm.examId
                }))
               setAssignTables(updatedAssignTable)                    
            }            
        }
        const handleToggle = (index) => {
            const updatedAssignTables = assignTables.map((item, i) => {
                if (i === index) {
                    return { ...item, isAssign: !item.isAssign };
                }
                return item;
            });
    
            setAssignTables(updatedAssignTables);
        };
    
        useEffect(() => {
            console.log(assignTables);
        }, [assignTables]);
        const itemPerPage=7
        const [currentPage,setCurrentPage]=useState(1)
        const totalPages = Math.ceil(assignTables.length/itemPerPage)
        const currentPageData = assignTables.slice((currentPage-1)*itemPerPage,currentPage*itemPerPage)
        return(
            <>
                <div className='progress'>
                    {
                        stepper.map((item,index)=>{
                            return(
                                <div key={index} className={currentState >= index+1 ?'done':'step-item'}>
                                    <div className={currentState >= index+1?'active-circle':'circle'}>
                                        { currentState> index+1 ? <i className="fa-solid fa-check"></i> : index+1}
                                    </div>
                                </div>                                
                            )
                        })
                    }
                </div>
                <div className='container1'>
                    <div className='title'>
                        <h2>{stepper[currentState-1]}</h2>
                    </div>
                    {
                        currentState===1 &&
                        <form>
                            <div className='input-field'>
                                <label>Exam Id</label>
                                <input type='number' disabled value={examForm.examId}/>
                            </div>
                            <div className='input-field'>
                                <label>Exam Name</label>
                                <input type='text' onChange={handle} name='examName' value={examForm.examName}/>
                            </div>
                            <div className='input-field'>
                                <label>Exam Category</label>
                                <select onChange={handle} name='examCategory' value={examForm.examCategory}>
                                    <option value=''>SELECT CATEGORY</option>
                                    {
                                       categories.length > 0 && categories.map((item,index)=>{
                                            return(
                                                <option key={index} value={item.CategoryName}>{item.CategoryName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='input-field'>
                                <label>Exam From</label>
                                <input type='datetime-local' onChange={handle} name='examFrom' value={examForm.examFrom}/>
                            </div>
                            <div className='input-field'>
                                <label>Exam To</label>
                                <input type='datetime-local' onChange={handle} name='examTo' value={examForm.examTo}/>
                            </div>
                            <div className='input-field'>
                                <label>Total Duration(minutes)</label>
                                <input type='number' min="0" onChange={handle} value={examForm.totalDuration} name='totalDuration'/>
                            </div>
                            <div className='input-field'>
                                <label>Total Marks</label>
                                <input type='number' min="0" onChange={handle} name='totalMarks' value={examForm.totalMarks} />
                            </div>
                            <div className='input-field'>
                                <label>No Of Questions</label>
                                <input type='number' min="0" onChange={handle} name='noOfQuestions' value={examForm.noOfQuestions} />
                            </div>
                        </form>
                    }
                    {   
                        currentState==2 &&
                        <div className='sections'>
                            <div className='form'>
                                <form style={{'padding':'30px 10px'}} onSubmit={addSection} onReset={onSectionReset}>
                                    <div className='input-field'>
                                        <label>Section No</label>
                                        <input type='number' value={card.length+1} disabled/>
                                    </div>
                                    <div className='input-field'>
                                        <label>Section Name</label>
                                        <input type='text' name='sectionName'  onChange={handleSection} value={sectionForm.sectionName}/>
                                    </div>
                                    <div className='input-field'>
                                        <label>Duration (minutes)</label>
                                        <input type='number' min="0" value={sectionForm.duration}  name='duration' onChange={handleSection}/>
                                    </div>
                                    <div className='input-field'>
                                        <label>No Of Questions</label>
                                        <input type='number' min="0" value={sectionForm.questions} name='questions' onChange={handleSection}/>
                                    </div>
                                    <div className='input-field'>
                                        <label>Section Marks</label>
                                        <input type='number' min="0" value={sectionForm.marks} name='marks' onChange={handleSection}/>
                                    </div>
                                    <div className='input-field'>
                                        <label>Subject Name</label>
                                        <select onChange={getChapters} name='subjectName' value={subjectName}>
                                            <option value=''>SELECT SUBJECT</option>
                                            {
                                                subjects.map((item,index)=>{
                                                    return(
                                                        <option key={index} value={item.SubjectName}>{item.SubjectName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='input-field'>
                                        <label>Chapter Name</label>
                                        <select onChange={handleSection} name='chapter' value={sectionForm.chapter}>
                                            <option value=''>SELECT CHAPTER</option>
                                            {
                                                chapters.map((item,index)=>{
                                                    return(
                                                        <option key={index} value={item.ChapterName}>{item.ChapterName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='input-field'>
                                        <label>Questions Left</label>
                                        <input type='number' min="0" value={qLeft} disabled/>
                                    </div>
                                    <div className='input-field'>
                                        <label>Duration Left</label>
                                        <input type='number' min="0" value={DLeft} disabled/>
                                    </div>
                                    <div className='input-field'>
                                        <label>Marks Left</label>
                                        <input type='number' min="0" value={MLeft} disabled/>
                                    </div>
                                    <div className='actions' style={{'marginTop':'10px'}}>
                                        <input type='submit' value={sectionActionStatus}/>
                                        <input type='reset' value='Cancel'/>
                                    </div>
                                </form>
                            </div>
                            <div className='table'>
                                {
                                    card.length > 0 ? (card.map((item,index)=>{
                                        return(
                                            <div className='card' key={index}>
                                                <div className='item'>
                                                    <p className='title-data'>Section No</p>
                                                    <p className='value'>{index+1}</p>
                                                </div>
                                                <div className='item'>
                                                    <p className='title-data'>Section Name</p>
                                                    <p className='value'>{item.sectionName}</p>
                                                </div>
                                                <div className='item'>
                                                    <p className='title-data'>Duration (minutes)</p>
                                                    <p className='value'>{item.duration}</p>
                                                </div>
                                                <div className='item'>
                                                    <p className='title-data'>No Of Questions</p>
                                                    <p className='value'>{item.questions}</p>
                                                </div>
                                                <div className='item'>
                                                    <p className='title-data'>Subject Name</p>
                                                    <p className='value'>{item.subjectName}</p>
                                                </div>
                                                <div className='item'>
                                                    <p className='title-data'>Chapter Name</p>
                                                    <p className='value'>{item.chapter}</p>
                                                </div>
                                                <div className='action-buttons'>                                                    
                                                    <button onClick={()=>deleteSection(index,item)}><i className='fa-solid fa-trash'></i></button>
                                                </div>
                                            </div>   
                                        )
                                    })
                                ):(
                                    <p>No Section avaialble !!</p>
                                )}                            
                            </div>
                            
                        </div>
                    }
                    {
                        currentState===3 &&
                        <>
                       
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>College Name</th>
                                    <th>Batch Name</th>
                                    <th>Date & Time</th>
                                    <th>Assign</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    assignTables.length > 0 ?(
                                        assignTables.map((item,index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{item.CollegeName}</td>
                                                    <td>{item.BatchName}</td>
                                                    <td>{examForm.examFrom.replace('T', ' ') + ' To ' + examForm.examTo.replace('T', ' ')}</td>
                                                    <td>
                                                    <div className="switch-container">
                                                        <input
                                                            type="checkbox"
                                                            id={`toggle-switch-${index}`}
                                                            className="toggle-switch"
                                                            checked={item.isAssign}
                                                            onChange={() => handleToggle(index)}
                                                        />
                                                        <label htmlFor={`toggle-switch-${index}`} className="switch-label"></label>
                                                    </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ):(
                                        <tr>
                                            <td colSpan="4">No Colleges available !!</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        {/* <div className="pagination">
                            <p>{currentPage} of {totalPages}</p>
                            <div className="buttons">
                                {
                                    Array.from({length:totalPages},(_,i)=>i+1).map((page,index)=>{
                                        return(
                                            <button onClick={()=>setCurrentPage(page)} className={currentPage===page ? 'selectedButton':''} key={index}>{page}</button>
                                        )
                                    })
                                }
                            </div>
                        </div> */}
                        </>
                    }
                    <div className='progress-action'>
                        <button onClick={previousState}>{stepper[currentState-2]}  {'<- Previous'} </button>
                        <button onClick={setData}>{currentState===3?'Assign & Finish':'Next -> ' +stepper[currentState]}</button>
                    </div>                    
                </div>
            </>
        )
    }

    function ViewExam(){
        const [exams,setExams]=useState([])
        const itemPerPage=10
        const [currentPage,setCurrentPage]=useState(1)
        const totalPages = Math.ceil(exams.length/itemPerPage)
        const currentPageData = exams.slice((currentPage-1)*itemPerPage,currentPage*itemPerPage)
        const getAllExams=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM exams')
            const data = await res.json()
            if(data.status){
                setExams(data.message)
            }
        }
        const getDate=(arg)=>{
            const now = new Date(arg);
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); 
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
            return formattedDateTime.replace('T',' ')
        }
        useEffect(()=>{
            getAllExams()
        },[])
        const studentEdit=(student)=>{            
        }
        const deleteExam=async(id)=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/deleteQuery/',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    sql:'DELETE FROM exams WHERE SrNo=?',
                    id:id
                })
            })
            const data = await res.json()
            if(data.status){
                if(data.message==='deleted'){
                    toast.success('Exam deleted !!')
                }
                getAllExams()
            } else {
                toast.error(data.message)
            }

        }
        return(
            <>
                <div className='container1'>
                    <div className='title'>
                        <h2>View Exams</h2>
                    </div>
                    
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Exam Category</th>
                                <th>Exam Name</th>
                                <th>Date & Time</th>
                                <th>Total Duration (minutes)</th>
                                <th>Total Marks</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentPageData.length > 0 ? (currentPageData.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{item.SrNo}</td>
                                            <td>{item.ExamCategory}</td>
                                            <td>{item.ExamName}</td>
                                            <td>{getDate(item.ExamFrom)} to {getDate(item.ExamTo)}</td>
                                            <td>{item.TotalDuration}</td>
                                            <td>{item.TotalMarks}</td>
                                            <td className="table-action">
                                                <button style={{display:'none'}} onClick={()=>studentEdit(item)} ><i className="fa-solid fa-pen"></i></button>
                                                <button onClick={()=>deleteExam(item.SrNo)}><i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })) : (
                                    <tr>
                                        <td colSpan="6">No students available</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <div className="pagination">
                        <p>{currentPage} of {totalPages}</p>
                        <div className="buttons">
                        {
                            Array.from({length:totalPages},(_,i)=>i+1).map((page,index)=>{
                                return(
                                    <button onClick={()=>setCurrentPage(page)} className={currentPage===page ? 'selectedButton':''} key={index}>{page}</button>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </>
        )   
    }

    return(
        <>
            { isLoading && <WaitingPanel/>}
            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='panel'>
                <div className='header'>
                    <button className={add ? 'activeButton':''} onClick={()=>actionClick('Add')}>Add Exam</button>
                    <button className={view ? 'activeButton':''} onClick={()=>actionClick('view')}>View Exams</button>
                </div>
                <div className='panel2'>
                    {
                        add &&
                        <AddExam examId={examId}/>
                        
                    }
                    {
                        view &&
                        <ViewExam/>
                    }
                </div>
            </div>
        </>
    )
}