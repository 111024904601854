import { useEffect, useState } from "react"
import WaitingPanel from '../WaitingPanel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';

export default function Subjects(){
    const [addChapter,setAddChapter]=useState(false)
    const [viewChpater,setViewChapter]=useState(false)
    
    
    function AddSubject(){        
        const [isLoading,setLoading]=useState(false)
        const [uniqueId,setUniqueId]=useState(0)
        const [subjectName,setSubjectName]=useState('')
        

        const getMaxSrNo=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getMaxSrNo/'+'SELECT MAX(SRNO) AS ID from subjects')
            const data= await res.json()
            if(data.status){
                setUniqueId(data.message+1)
            }
        }
        
        useEffect(()=>{
            getMaxSrNo()
            
        },[])
        const addSubject=async(e)=>{
            e.preventDefault()
            if(subjectName===''){
                toast.error('Enter subject name !!')
                return;
            }
            const res = await fetch('https://api.leadsoftbackend.com/api/addSubject',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    subjectName:subjectName
                })
            })
            const data = await res.json()
            if(data.status){
                toast.success(data.message)
                setSubjectName('')
                getMaxSrNo()
            } else {
                toast.error(data.message)
            }
        }
        return(
            <>
                {
                    isLoading &&
                    <WaitingPanel/>
                }
                <ToastContainer
                    position="top-right"
                    autoClose={2500}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <div className="container1">
                    <div className="title">
                        <h2>Add Subject</h2>
                    </div>
                    <form onSubmit={addSubject}>
                        <div className="input-field">
                            <label>Serial No</label>
                            <input type='number' value={uniqueId} disabled/>
                        </div>                       
                        <div className="input-field">
                            <label>Subject Name</label>
                            <input type='text' value={subjectName} onChange={(e)=>setSubjectName(e.target.value)} />
                        </div>
                        <div className="actions">
                            <input type='submit' value='Add Subject'></input>
                            <input type='reset' value='Cancel'></input>
                        </div>
                    </form>
                </div>
            </>
        )
    }
    function ViewSubject(){        
        const [isLoading,setLoading]=useState(false)
        const [subjects,setSubjects]=useState([])
        const getAllSubjects=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getAllSubjects')
            const data= await res.json()
            if(data.status){
                setSubjects(data.message)
            }
        }
        useEffect(()=>{
            getAllSubjects()
        },[])
        return(
            <>
                {
                    isLoading &&
                    <WaitingPanel/>
                }
                <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
                />
                <div className="container1">
                    <div className="title">
                        <h2>View Subject</h2>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Subject Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            subjects.length > 0 ? subjects.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>{item.SubjectName}</td>
                                    </tr>
                                )
                            }) :
                            (
                                <tr>
                                    <td colSpan="3">No Subjects avaialble !!</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </>
        )
    }
    const setActive=(arg)=>{
        if(arg==='add'){
            setAddChapter(true)
            setViewChapter(false)
        } else {
            setViewChapter(true)
            setAddChapter(false)
        }
    }
    return(
        <>
            <div className='panel'>
                <div className='header'>
                    <button className={addChapter?'activeButton':''} onClick={()=>setActive('add')}>Add Subject</button>
                    <button className={viewChpater?'activeButton':''} onClick={()=>setActive('view')}>View Subject</button>
                </div>
                <div className='panel2'>
                    {
                        addChapter &&
                        <AddSubject/>
                        
                    }
                    {
                        viewChpater &&
                        <ViewSubject/>
                    }
                </div>
            </div>
            
        </>
    );
}