
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/Login';
import Account from './components/Dashboard/Account';

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path='/' Component={Login}/>
            <Route path='/account' Component={Account}/>
            
        </Routes>
    </BrowserRouter>
  );
}

export default App;
