import './style.css';
import WaitingPanel from '../WaitingPanel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';

export default function ExamCateogry(){
    const [isLoading,setLoading]=useState(false)
    const [editStatus,setEditStatus]=useState(false)
    const [actionStatus,setActionStatus]=useState('Add Category')

    const [editRecord,setEditRecord]=useState({})
    function AddCategory(){
        const [categoryForm,setCategoryForm]=useState({
            categoryName:'',collegeName:'',batchName:''
        })
        
        const [categoryId,setCategoryId]=useState(0)
        const handle=(e)=>{
            setCategoryForm({
                ...categoryForm,
                [e.target.name]:e.target.value
            })
        }
        const getCategoryId=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getMaxSrNo/SELECT MAX(SrNo) AS ID FROM exam_category')
            const data = await res.json()            
            if(data.status){
                if(data.message===null){
                    setCategoryId(1)
                } else {
                    setCategoryId(data.message+1)
                }
            }
        }
        useEffect(()=>{
            if(editStatus===true){
                setCategoryId(editRecord.SrNo)
                setCategoryForm({
                    ...categoryForm,
                    categoryName:editRecord.CategoryName,
                    collegeName:editRecord.CollegeName,
                    batchName:editRecord.BatchName
                })
                setActionStatus('Update Category')
            } else {
                getCategoryId()
            }
        },[])
        const [colleges,setColleges]=useState([])
        const [batches,setBatches]=useState([])
        const getColleges=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM colleges')
            const data = await res.json()
            if(data.status){
                setColleges(data.message)
            }
        }
        useEffect(()=>{
            getColleges()
        },[])
        const handleCollege=async(e)=>{
            setCategoryForm({
                ...categoryForm,
                collegeName:e.target.value
            })
            const res = await fetch("https://api.leadsoftbackend.com/api/getData/SELECT * FROM batches WHERE CollegeName='"+e.target.value+"'")
            const data = await res.json()
            if(data.status){
                setBatches(data.message)
            }
        }
        const categorySubmit=async(e)=>{
            e.preventDefault()
            if(categoryForm.collegeName===''||categoryForm.batchName===''||categoryForm.categoryName===''){
                toast.error('Enter details !!')
                return
            }
            if(actionStatus==='Add Category'){
                setLoading(true)
                const res = await fetch('https://api.leadsoftbackend.com/api/addCategory',{
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json'
                    },
                    body:JSON.stringify(categoryForm)
                })
                const data = await res.json()
                setLoading(false)
                if(data.status){
                    toast.success(data.message)
                    setCategoryForm({
                        categoryName:'',collegeName:'',batchName:''
                    })
                    getCategoryId()
                } else {
                    toast.error(data.message)
                }
            }
            else if(actionStatus==='Update Category'){
                setLoading(true)
                const res = await fetch('https://api.leadsoftbackend.com/api/updateCategory/'+categoryId,{
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json'
                    },
                    body:JSON.stringify(categoryForm)
                })
                const data = await res.json()
                setLoading(false)
                if(data.status){
                    toast.success(data.message)
                    setCategoryForm({
                        categoryName:'',collegeName:'',batchName:''
                    })
                    getCategoryId()
                    setActionStatus('Add Category')
                    setEditStatus(false)
                } else {
                    toast.error(data.message)
                }
            }
        }
        return(
            <>
                <div className='container1'>
                    <div className='title'>
                        <h2>{actionStatus}</h2>
                    </div>
                    <form onSubmit={categorySubmit}>
                        <div className='input-field'>
                            <label>Category Id</label>
                            <input type='number' value={categoryId} disabled/>
                        </div>
                        <div className='input-field'>
                            <label>Category Name</label>
                            <input type='text' name='categoryName' value={categoryForm.categoryName} onChange={handle}/>
                        </div>
                        <div className="input-field">
                            <label>College Name</label>
                            <select name='collegeName'  value={categoryForm.collegeName} onChange={handleCollege}>
                                <option value=''>SELECT</option>
                                {
                                    colleges.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.AliasName}>{item.AliasName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-field">
                            <label>Batch Name</label>
                            <select name='batchName' value={categoryForm.batchName} onChange={handle} >
                                <option value=''>SELECT</option>
                                {
                                    batches.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.BatchName}>{item.BatchName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="actions">
                            <input type='submit' value={actionStatus}></input>
                            <input type='reset' value='Cancel'></input>
                        </div>
                    </form>
                </div>
            </>
        )
    }

    function ViewCategory(){
        const [categories,setCategory]=useState([])
        const itemPerPage=10
        const [currentPage,setCurrentPage]=useState(1)
        const totalPages = Math.ceil(categories.length/itemPerPage)
        const currentPageData = categories.slice((currentPage-1)*itemPerPage,currentPage*itemPerPage)
        const getAllCategory=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM exam_category')
            const data = await res.json()
            if(data.status){
                setCategory(data.message)
            }
        }
        useEffect(()=>{
            getAllCategory()
        },[])
        const categoryEdit=(category)=>{
            setOptions('add')
            setEditRecord(category)
            setEditStatus(true)
        }
        const deleteCategory=async(id)=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/deleteCategory/'+id,{
                method:'DELETE',
                headers:{
                    'Content-Type':'application/json'
                }
            })
            const data = await res.json()
            if(data.status){
                toast.success(data.message)
                getAllCategory()
            } else {
                toast.error(data.message)
            }

        }
        return(
            <>
                <div className='container1'>
                    <div className='title'>
                        <h2>View Categories</h2>
                    </div>                  
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Category Name</th>
                                <th>College Name</th>
                                <th>Batch Name</th>                                
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentPageData.length > 0 ? (currentPageData.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{item.CategoryName}</td>
                                            <td>{item.CollegeName}</td>
                                            <td>{item.BatchName}</td>
                                            
                                            <td className="table-action">
                                                <button onClick={()=>categoryEdit(item)} ><i className="fa-solid fa-pen"></i></button>
                                                <button onClick={()=>deleteCategory(item.SrNo)}><i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })) : (
                                    <tr>
                                        <td colSpan="6">No categories available</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <div className="pagination">
                        <p>{currentPage} of {totalPages}</p>
                        <div className="buttons">
                        {
                            Array.from({length:totalPages},(_,i)=>i+1).map((page,index)=>{
                                return(
                                    <button onClick={()=>setCurrentPage(page)} className={currentPage===page ? 'selectedButton':''} key={index}>{page}</button>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </>
        )   
    }

    const [add,setAdd]=useState(false)
    const [view,setView]=useState(false)
    const setOptions=(arg)=>{
        if(arg==='add'){
            setAdd(true)
            setView(false)
        } else if(arg==='view'){
            setView(true)
            setAdd(false)
        }
    }
    return(
        <>
            { isLoading  && <WaitingPanel/>}
            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='panel'>
                <div className='header'>
                    <button onClick={()=>setOptions('add')} className={add ? 'activeButton' : ''}>{actionStatus}</button>
                    <button onClick={()=>setOptions('view')} className={view ? 'activeButton' : ''}>View Category</button>
                </div>
                <div className='panel2'>
                    { add  && <AddCategory/> }
                    { view  && <ViewCategory/> }
                </div>
            </div>
        
        </>
    )
}