import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import './Account.css';
import { useEffect, useState } from 'react';
import Student from './Student';
import Chapters from './Chapters';
import Subjects from './Subjects';
import Questions from './Questions';
import ExamCateogry from './ExamCategory';
import Colleges from './Colleges';
import Batches from './Batches';
import Courses from './Courses';
import Exams from './Exams';

export default function Account(){
    const navigate=useNavigate()
    const [actionElements,setElements]=useState([
        {
            name:'Colleges',
            class:'',
            isVisible:false
        },
        {
            name:'Batches',
            class:'',
            isVisible:false
        },
        {
            name:'Students',
            class:'',
            isVisible:false
        },
        {
            name:'Subjects',
            class:'',
            isVisible:false
        },
        {
            name:'Chapters',
            class:'',
            isVisible:false
        },
        {
            name:'Questions',
            class:'',
            isVisible:false
        },
        {
            name:'Courses',
            class:'',
            isVisible:false
        },
        {
            name:'Exam Category',
            class:'',
            isVisible:false
        },
        {
            name:'Exams',
            class:'',
            isVisible:false
        }
    ])
    useEffect(()=>{
        if(localStorage.getItem('username')==null){
            navigate('/')
        }
    })
    const elementClick=(index,action)=>{
        
        setElements(()=>{
            const temp=[...actionElements]
            for(let i=0;i<actionElements.length;i++){
                if(i==index){                    
                    temp[i].class='active'
                    temp[i].isVisible=true
                } else {
                    temp[i].class=''
                    temp[i].isVisible=false
                }
            }
            return temp
        })
    }
    const logout=()=>{
        localStorage.removeItem('username')
        navigate('/')
    }
    return(
        <>
            <div className='menu'>
                <div className='title'>
                    <h2>LeadSoft</h2>
                </div>
                <div className='actions'>
                    {
                        actionElements.map((item,index)=>{
                            return(
                                <button key={index} onClick={()=>elementClick(index,item.name)} className={item.class}>{item.name}</button>
                            )
                        })
                    }
                    <button onClick={logout} className='logout'>Logout</button>
                </div>
            </div>
            <div className='container'>
                {
                    actionElements[0].isVisible &&
                    <Colleges/>
                }
                {
                    actionElements[1].isVisible &&
                    <Batches/>
                }
                {
                    actionElements[2].isVisible &&
                    <Student/>
                }
                {
                    actionElements[3].isVisible &&
                    <Subjects/>
                }
                {
                    actionElements[4].isVisible &&
                    <Chapters/>
                }
                {
                    actionElements[5].isVisible &&
                    <Questions/>
                }
                {
                    actionElements[6].isVisible &&
                    <Courses/>
                }
                {
                    actionElements[7].isVisible &&
                    <ExamCateogry/>
                }
                {
                    actionElements[8].isVisible && 
                    <Exams/>
                }
            </div>
        </>
    )
}