import { useEffect, useState } from 'react';
import './Login.css';
import WaitingPanel from './WaitingPanel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export default function Login(){
    const navigate=useNavigate()
    const [isLoding,setLoading]=useState(false)
    const [loginForm,setLoginForm]=useState({
        username:'',
        password:''
    })
    useEffect(()=>{
        if(localStorage.getItem('username')!=null){
            navigate('/account')
        }
    })
    const handleEdit=(e)=>{              
        setLoginForm({
            ...loginForm,
            [e.target.name]:e.target.value
        })
    }
    const handleLogin=async(e)=>{
        e.preventDefault();        
        if(loginForm.username=='' || loginForm.password==''){
            toast.error('Enter credentails !!')
            return
        }
        setLoading(true)
        setTimeout(async() => {            
            const res = await fetch('https://api.leadsoftbackend.com/api/login',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(loginForm)
            })
            const data =await res.json()
            setLoading(isLoding=>!isLoding)
            if(data.status){
                toast.success(data.message)
                setTimeout(() => {
                    localStorage.setItem('username',loginForm.username)
                    navigate('/account')
                }, 2500);
            } else {
                toast.error(data.message)
            }
        }, 1200);
        
    }
    return(
        <>
            <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
            />
            <main>
                <div className='login'>
                    <h2>Admin Login</h2>
                    <form onSubmit={handleLogin}>
                        <div className='input-field'>
                            <label>Username</label>
                            <input type='text' name='username' value={loginForm.username}
                                onChange={handleEdit}></input>
                        </div>
                        <div className='input-field'>
                            <label>Password</label>
                            <input type='password' name='password' value={loginForm.password}
                                onChange={handleEdit}></input>
                        </div>
                        <input type='submit' value='Login'></input>
                    </form>
                </div>
            </main>
            {
                isLoding && 
                <WaitingPanel/>
            }
        </>
    )
}