import { useEffect, useState } from 'react';
import './style.css';
import * as XLSX from 'xlsx'
import WaitingPanel from '../WaitingPanel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Student(){
    const [singleStudent,setSingleStudent]=useState(false)
    const [bulkStudent,setBulkStudent]=useState(false)
    const [viewStudent,setViewStudent]=useState(false)
    const [actionStatus,setStatus]=useState('Add Single Student')
    const [editStudent,setEditStudent]=useState({})
    const handleSubComponents=(action)=>{
        if(action=='Add Single Student'){
            setBulkStudent(false)
            setViewStudent(false)
            setSingleStudent(true)
        }
        else if(action=='Add Bulk Student'){
            setBulkStudent(true)
            setViewStudent(false)
            setSingleStudent(false)
        } else {
            setBulkStudent(false)
            setViewStudent(true)
            setSingleStudent(false)
        }
    }
    function AddSingleStudent(){
        const [uniqueId,setUniqueId]=useState(0)
        const [isLoading,setLoading]=useState(false)
        const [colleges,setColleges]=useState([])
        const [batches,setBatches]=useState([])
        const [studentForm,setStudentForm]=useState({
            college:'',
            batch:'',
            passoutYear:'',
            fullname:'',
            email:'',
            mobile:'',
            dob:'',
            gender:'',
            password:''
        })
    
        const handleGender=(val)=>{
            setStudentForm(
                {...studentForm,
                    gender:val
                }            
            )
        }
        const handleChange=(e)=>{
            setStudentForm({
                ...studentForm,
                [e.target.name]:e.target.value
            })
        }
        const handleCollege=async(e)=>{
            setStudentForm({
                ...studentForm,
                college:e.target.value
            })
            const res = await fetch("https://api.leadsoftbackend.com/api/getData/SELECT * FROM batches WHERE CollegeName='"+e.target.value+"'")
            const data = await res.json()
            if(data.status){
                setBatches(data.message)
            }
        }
        const getMaxId=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getMaxId')
            const data = await res.json()        
            if(data.status){
                setUniqueId(data.message+1)
            }
        }
        const addStudent=async(e)=>{
            e.preventDefault()
            if(studentForm.batch==='' || studentForm.college===''||studentForm.dob==='' || studentForm.email===''||studentForm.fullname===''||studentForm.gender===''||studentForm.mobile===''||studentForm.passoutYear===''||studentForm.password===''){
                toast.error('Enter all field !!')
                return
            }
            if(actionStatus==='Add Single Student'){
                setLoading(true)
                const res = await fetch('https://api.leadsoftbackend.com/api/addSingleStudent',{
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json'
                    },
                    body:JSON.stringify(studentForm)
                })
                const data=await res.json()
                setLoading(false)
                if(data.status){
                    toast.success(data.message)
                    getMaxId()
                    setStudentForm({
                        college:'',
                        batch:'',
                        passoutYear:'',
                        fullname:'',
                        email:'',
                        mobile:'',
                        dob:'',
                        gender:'',
                        password:''
                    })
                } else {
                    toast.error(data.message)
                }
            } else if(actionStatus==='Edit Student'){
                setLoading(true)
                const res = await fetch('https://api.leadsoftbackend.com/api/updateStudent',{
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json'
                    },
                    body:JSON.stringify(studentForm)
                })
                const data=await res.json()
                setLoading(false)
                if(data.status){
                    toast.success(data.message)
                    setStatus('Add Single Student')
                    getMaxId()
                    setStudentForm({
                        college:'',
                        batch:'',
                        passoutYear:'',
                        fullname:'',
                        email:'',
                        mobile:'',
                        dob:'',
                        gender:'',
                        password:''
                    })
                } else {
                    toast.error(data.message)
                }
            }
        }
        const getAllColleges=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM colleges')
            const data = await res.json()
            if(data.status){
                setColleges(data.message)
            }
        }
        useEffect(()=>{
            getAllColleges()
            if(actionStatus==='Add Single Student'){
                getMaxId()
            } else {
                
                const temp={
                    college:editStudent.CollegeName,
                    batch:editStudent.BatchName,
                    passoutYear:editStudent.PassoutYear,
                    fullname:editStudent.FullName,
                    email:editStudent.EmailAddress,
                    mobile:editStudent.MobileNo,
                    dob:editStudent.DateOfBirth,
                    gender:editStudent.Gender,
                }
                console.log(temp)
                setUniqueId(editStudent.Id)
                setStudentForm({...temp})
            }
        },[])
        return(
            <>
                {
                    isLoading &&
                    <WaitingPanel/>
                }
               
                <div className="container1">
                    <div className="title">
                        <h2>{actionStatus}</h2>
                    </div>
                    <form onSubmit={addStudent}>
                        <div className="input-field">
                            <label>Unique Id</label>
                            <input type='number' value={uniqueId} disabled/>
                        </div>
                        <div className="input-field">
                            <label>College Name</label>
                            <select name='college' value={studentForm.college} onChange={handleCollege}>
                                <option value=''>SELECT</option>
                                {
                                    colleges.map((item,index)=>{
                                        return(
                                            <option value={item.AliasName} key={index}>{item.AliasName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-field">
                            <label>Batch Name</label>
                            <select name='batch' value={studentForm.batch} onChange={handleChange}>
                                <option value=''>SELECT</option>
                                {
                                    batches.map((item,index)=>{
                                        return(
                                            <option value={item.BatchName} key={index}>{item.BatchName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-field">
                            <label>Pass out year</label>
                            <select name='passoutYear' value={studentForm.passoutYear} onChange={handleChange}>
                                <option value=''>SELECT</option>
                                <option value='2023-2024'>2023-2024</option>
                                <option value='2024-2025'>2024-2025</option>
                                <option value='2025-2026'>2025-2026</option>
                            </select>
                        </div>
                        <div className="input-field">
                            <label>Fullname</label>
                            <input type='text' name='fullname' value={studentForm.fullname} onChange={handleChange}/>
                        </div>
                        <div className="input-field">
                            <label>Email Address</label>
                            <input type='email' name="email" disabled={actionStatus==='Edit Student'} value={studentForm.email} onChange={handleChange}/>
                        </div>
                        <div className="input-field">
                            <label>Mobile No</label>
                            <input type='number' name="mobile" value={studentForm.mobile} onChange={handleChange}/>
                        </div>
                        <div className="input-field">
                            <label>Date Of Birth</label>
                            <input type='date' name='dob' value={studentForm.dob} onChange={handleChange}/>
                        </div>
                        <div className="input-field">
                            <label>Gender</label>                        
                            <div className="radio-div">
                                <div className="box">
                                    <div onClick={()=>handleGender('Male')} className={studentForm.gender==='Male'?'radio-selected':'circle'}>
    
                                    </div>
                                    <p>Male</p>
                                </div>
                                <div className="box">
                                    <div onClick={()=>handleGender('Female')} className={studentForm.gender==='Female'?'radio-selected':'circle'}>
    
                                    </div>
                                    <p>Female</p>
                                </div>
                            </div>
                        </div>
                        { actionStatus!='Edit Student' && <div className="input-field">
                            <label>Username</label>
                            <input type='email' disabled value={studentForm.email}/>
                        </div>}
                        { actionStatus!='Edit Student' && <div className="input-field">
                            <label>Pasword</label>
                            <input type='text' name='password' value={studentForm.password} onChange={handleChange}/>
                        </div>}
                        <div className="actions">
                            <input type='submit' value={actionStatus}></input>
                            <input type='reset' value='Cancel'></input>
                        </div>
                    </form>
                </div>
            </>
        )
    }
    function AddBulkStudent(){
        const [isLoading,setLoading]=useState(false)
        const [excelData,setExcelData]=useState([])
        const [college,setCollege]=useState('')
        const [batch,setBatch]=useState('')
        const [passoutYear,setPassoutYear]=useState('')
        const [colleges,setColleges]=useState([])
        const [batches,setBatches]=useState([])
        const getColleges=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM colleges')
            const data = await res.json()
            if(data.status){
                setColleges(data.message)
            }
        }
        useEffect(()=>{
            getColleges()
        },[])
        const handleCollege=async(e)=>{
            setCollege(e.target.value)
            const res = await fetch("https://api.leadsoftbackend.com/api/getData/SELECT * FROM batches WHERE CollegeName='"+e.target.value+"'")
            const data = await res.json()
            if(data.status){
                setBatches(data.message)
            }
        }
        const handleFile=(event)=>{
            const file = event.target.files[0]
            const reader = new FileReader()
    
            reader.onload=(e)=>{
                const binaryStr = e.target.result
                const workBook = XLSX.read(binaryStr,{type:'binary'})
                const sheetName = workBook.SheetNames[0]
                const sheet = workBook.Sheets[sheetName]
                const allData = XLSX.utils.sheet_to_json(sheet,{header:1})
                setExcelData(allData)
            }
            reader.readAsBinaryString(file)
        }
        const onBulkStudentSubmit=async(e)=>{
            e.preventDefault()
            if(college===''||batch===''||passoutYear===''){
                toast.error('Enter details !!')
                return
            }
            setLoading(true)
            const student={
                fullname:'',college:'',batch:'',email:'',mobile:'',dob:'',gender:'',passoutYear:'',
                password:''
            }
            let flag=true
            for(let i=1;i<excelData.length;i++){
                student.fullname=excelData[i][0]
                student.college=college
                student.batch=batch
                student.passoutYear=passoutYear
                student.email=excelData[i][1]
                student.mobile=excelData[i][2]
                student.dob=excelData[i][3]
                student.gender=excelData[i][4]
                student.password=excelData[i][5]
                const res = await fetch('https://api.leadsoftbackend.com/api/addSingleStudent',{
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json'
                    },
                    body:JSON.stringify(student)
                })
                const data = await res.json()
                if(!data.status){
                    flag=false
                    break
                }
            }
            setLoading(false)
            if(flag){
                toast.success(excelData.length-1+' Students added !!')
                setBatch('')
                setCollege('')
                setPassoutYear('')
                setExcelData([])
            } else {
                toast.error('Internal server error')
            }
        }
        return(
            <>
                { isLoading  && <WaitingPanel/> }
                <div className='container1'>
                    <div className='title'>
                        <h2>Add Bulk Students</h2>
                    </div>
                    <form onSubmit={onBulkStudentSubmit}>
                        <div className="input-field">
                            <label>College Name</label>
                            <select name='college' value={college} onChange={(e)=>handleCollege(e)}>
                                <option value=''>SELECT</option>
                                {
                                    colleges.map((item,index)=>{
                                        return(
                                            <option value={item.AliasName} key={index}>{item.AliasName}</option>
                                        )
                                    })                                    
                                }
                            </select>
                        </div>
                        <div className="input-field">
                            <label>Batch Name</label>
                            <select name='batch' value={batch} onChange={(e)=>setBatch(e.target.value)}>
                                <option value=''>SELECT</option>
                                {
                                    batches.map((item,index)=>{
                                        return(
                                            <option value={item.BatchName} key={index}>{item.BatchName}</option>
                                        )
                                    })                                    
                                }
                            </select>
                        </div>
                        <div className="input-field">
                            <label>Pass out year</label>
                            <select name='passoutYear' value={passoutYear} onChange={(e)=>setPassoutYear(e.target.value)}>
                                <option value=''>SELECT</option>
                                <option value='2023-2024'>2023-2024</option>
                                <option value='2024-2025'>2024-2025</option>
                                <option value='2025-2026'>2025-2026</option>
                            </select>
                        </div>
                        <div className='input-field'>
                            <label>Select File</label>
                            <input type='file' accept='.xlsx,.xls' onChange={handleFile}/>
                        </div>
                        <div className="actions">
                            <input type='submit' value='Add Students'></input>
                            <input type='reset' value='Cancel'></input>
                        </div>
                    </form>
                </div>
            </>
        )   
    }
    function ViewStudentComponent(){
        const [students,setStudents]=useState([])
        const itemPerPage=10
        const [currentPage,setCurrentPage]=useState(1)
        const totalPages = Math.ceil(students.length/itemPerPage)
        const currentPageData = students.slice((currentPage-1)*itemPerPage,currentPage*itemPerPage)
        const getAllStudents=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM students')
            const data = await res.json()
            if(data.status){
                setStudents(data.message)
            }
        }
        useEffect(()=>{
            getAllStudents()
        },[])
        const studentEdit=(student)=>{
            handleSubComponents('Add Single Student')
            setStatus('Edit Student')
            setEditStudent(student)
        }
        const deleteStudent=async(id)=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/deleteStudent/'+id,{
                method:'DELETE',
                headers:{
                    'Content-Type':'application/json'
                }
            })
            const data = await res.json()
            if(data.status){
                toast.success(data.message)
                getAllStudents()
            } else {
                toast.error(data.message)
            }

        }
        return(
            <>
                <div className='container1'>
                    <div className='title'>
                        <h2>View Students</h2>
                    </div>
                    <div className='filter'>
                        <label>Email address to search student</label>
                        <input type='email'/>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>College Name</th>
                                <th>Batch Name</th>
                                <th>Passout Year</th>
                                <th>Fullname</th>
                                <th>Email Address</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentPageData.length > 0 ? (currentPageData.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{item.Id}</td>
                                            <td>{item.CollegeName}</td>
                                            <td>{item.BatchName}</td>
                                            <td>{item.PassoutYear}</td>
                                            <td>{item.FullName}</td>
                                            <td>{item.EmailAddress}</td>
                                            <td className="table-action">
                                                <button onClick={()=>studentEdit(item)} ><i className="fa-solid fa-pen"></i></button>
                                                <button onClick={()=>deleteStudent(item.Id)}><i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })) : (
                                    <tr>
                                        <td colSpan="6">No students available</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <div className="pagination">
                        <p>{currentPage} of {totalPages}</p>
                        <div className="buttons">
                        {
                            Array.from({length:totalPages},(_,i)=>i+1).map((page,index)=>{
                                return(
                                    <button onClick={()=>setCurrentPage(page)} className={currentPage===page ? 'selectedButton':''} key={index}>{page}</button>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </>
        )   
    }
    return(
        <>
            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='panel'>
                <div className='header'>
                    <button className={singleStudent ? 'activeButton':''} onClick={()=>handleSubComponents('Add Single Student')}>{actionStatus}</button>
                    <button className={bulkStudent ? 'activeButton':''} onClick={()=>handleSubComponents('Add Bulk Student')}>Add Bulk Student</button>
                    <button className={viewStudent?'activeButton':''} onClick={()=>handleSubComponents('View Student')}>View Student</button>
                </div>
                <div className='panel2'>
                    {
                        singleStudent &&
                        <AddSingleStudent/>
                        
                    }
                    {
                        bulkStudent &&
                        <AddBulkStudent/>
                    }
                    {
                        viewStudent &&
                        <ViewStudentComponent/>
                    }
                </div>
            </div>
        </>
    )
}
