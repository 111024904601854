import { useEffect, useState } from 'react';
import './style.css';
import { ToastContainer, toast } from 'react-toastify';
import WaitingPanel from '../WaitingPanel';

export default function Batches(){

    const [add,setAdd]=useState(false)
    const [colleges,setColleges]=useState([])
    const [isLoading,setLoading]=useState(false)
    const [view,setView]=useState(false)
    const [actionStatus,setActionStatus]=useState('Add Batch')
    const [editRecord,setEditRecord]=useState({})
    const actionClick=(arg)=>{
        if(arg=='Add'){
            setAdd(true)
            setView(false)
        } else {
            setView(true)
            setAdd(false)
            setActionStatus('Add Batch')
        }
    }
    const getColleges=async()=>{
        const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM colleges')
        const data = await res.json()
        if(data.status){
            setColleges(data.message)
        }
    }
    useEffect(()=>{
        getColleges()
    },[])
    function AddBatch(){
        
        const [batchForm,setBatchForm]=useState({
            batchId:0,colgName:'',batchName:''
        })
        const handleBatchId=(arg)=>{
            setBatchForm({
                ...batchForm,
                batchId:arg
            })
        }
        const handle=(e)=>{
            setBatchForm({
                ...batchForm,
                [e.target.name]:e.target.value
            })
        }
       
        const getBatchId=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getMaxSrNo/SELECT MAX(SrNo) AS ID FROM batches')
            const data = await res.json()            
            if(data.status){
                if(data.message===null){
                    handleBatchId(1)
                } else {
                    handleBatchId(data.message+1)
                }
            }
        }
        useEffect(()=>{            
            if(actionStatus==='Update Batch'){
                setBatchForm({
                    batchId:editRecord.SrNo,
                    colgName:editRecord.CollegeName,
                    batchName:editRecord.BatchName
                })
            } else {
                getBatchId()
            }
        },[])
        const onValidate=()=>{
            if(batchForm.colgName===''){
                toast.error('Select college name')
                return false
            } else if(batchForm.batchName===''){
                toast.error('Enter batch name');
                return false
            } else {
                return true
            }
        }
        const onBatchFormSubmit=async(e)=>{
            e.preventDefault()
            let check = onValidate()
            if(check){
                if(actionStatus==='Add Batch'){
                    setLoading(true)
                    const res = await fetch('https://api.leadsoftbackend.com/api/addBatch',{
                        method:'POST',
                        headers:{
                            'Content-Type':'application/json'
                        },
                        body:JSON.stringify(batchForm)
                    })
                    const data= await res.json()
                    setLoading(false)
                    if(data.status){
                        setBatchForm({
                            batchId:0,colgName:'',batchName:''
                        })
                        getBatchId()
                        toast.success(data.message)
                    } else {
                        toast.error(data.message)
                    }
                } else if(actionStatus==='Update Batch'){
                    setLoading(true)
                    const res = await fetch('https://api.leadsoftbackend.com/api/UpdateBatch',{
                        method:'POST',
                        headers:{
                            'Content-Type':'application/json'
                        },
                        body:JSON.stringify(batchForm)
                    })
                    const data= await res.json()
                    setLoading(false)
                    if(data.status){
                        setBatchForm({
                            batchId:0,colgName:'',batchName:''
                        })
                        getBatchId()
                        toast.success(data.message)
                        setActionStatus('Add Batch')                        
                    } else {
                        toast.error(data.message)
                    }
                }
            }
        }
        return(
            <>
                <div className='container1'>
                    <div className='title'>
                        <h2>{actionStatus}</h2>
                    </div>
                    <form onSubmit={onBatchFormSubmit}>
                        <div className='input-field'>
                            <label>Batch Id</label>
                            <input value={batchForm.batchId} type='number' disabled/>
                        </div>
                        <div className='input-field'>
                            <label>College Name</label>
                            <select name="colgName" value={batchForm.colgName} onChange={handle}>
                                <option value=''>SELECT</option>
                                {
                                    colleges.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.AliasName}>{item.AliasName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='input-field'>
                            <label>Batch Name</label>
                            <input type='text' name='batchName' value={batchForm.batchName} onChange={handle} style={{'textTransform':'uppercase'}}/>
                        </div>
                        <div className='actions'>
                            <input type='submit' value={actionStatus}/>
                            <input type='reset' value='Cancel'/>
                        </div>
                    </form>
                </div>
            </>
        )
    }
    function ViewBatch(){        
        const itemPerPage=10
        const [currentPage,setCurrentPage]=useState(1)        
        const [batches,setBatch]=useState([])
        const totalPages = Math.ceil(batches.length/itemPerPage)
        const currentPageData = batches.slice((currentPage-1)*itemPerPage,currentPage*itemPerPage)
        const getBatches=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM batches')
            const data = await res.json()
            if(data.status){
                setBatch(data.message)
            }
        }
        useEffect(()=>{            
            getBatches()
        },[])
        const collegeEdit=(colg)=>{
            actionClick('Add')
            setEditRecord(colg)
            setActionStatus('Update Batch')
        }
        const deleteColg=async(id)=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/deleteQuery/',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    sql:'DELETE FROM batches WHERE SrNo=?',
                    id:id
                })
            })
            const data = await res.json()
            if(data.status){
                if(data.message==='deleted'){
                    toast.success('Batch deleted !!')
                    getBatches()
                }                
            } else {
                toast.error(data.message)
            }

        }
        return(
            <>
                <div className='container1'>
                    <div className='title'>
                        <h2>View Batches</h2>
                    </div>
                    <div className='filter'>
                        <label>Select College Name</label>
                        <select>
                            <option value=''>SELECT</option>
                            {
                                colleges.map((item,index)=>{
                                    return(
                                        <option key={index} value={item.AliasName}>{item.AliasName}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>College Name</th>
                                <th>Batch Name</th>                               
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentPageData.length > 0 ? (currentPageData.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{item.SrNo}</td>
                                            <td>{item.CollegeName}</td>
                                            <td>{item.BatchName}</td>
                                            <td className="table-action">
                                                <button onClick={()=>collegeEdit(item)} ><i className="fa-solid fa-pen"></i></button>
                                                <button onClick={()=>deleteColg(item.SrNo)}><i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })) : (
                                    <tr>
                                        <td colSpan="3">No batches available</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <div className="pagination">
                        <p>{currentPage} of {totalPages}</p>
                        <div className="buttons">
                        {
                            Array.from({length:totalPages},(_,i)=>i+1).map((page,index)=>{
                                return(
                                    <button onClick={()=>setCurrentPage(page)} className={currentPage===page ? 'selectedButton':''} key={index}>{page}</button>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </>
        )   
    }
    return(
        <>
            { isLoading && <WaitingPanel/>}
            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='panel'>
                <div className='header'>
                    <button className={add ? 'activeButton':''} onClick={()=>actionClick('Add')}>{actionStatus}</button>
                    <button className={view ? 'activeButton':''} onClick={()=>actionClick('view')}>View Batches</button>
                </div>
                <div className='panel2'>
                    {
                        add &&
                        <AddBatch/>
                        
                    }
                    {
                        view &&
                        <ViewBatch/>
                    }
                </div>
            </div>
        </>
    )
}