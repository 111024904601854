import { ToastContainer, toast } from 'react-toastify';
import './style.css';
import WaitingPanel from '../WaitingPanel';
import * as XLSX from 'xlsx'
import { useEffect, useRef, useState } from 'react';

export default function Courses(){
    const [isLoading,setLoading]=useState(false)
    const [actionStatus,setActionStatus]=useState('Add Course')
    const [add,setAdd]=useState(false)
    const [view,setView]=useState(false)
    const [assign,setAssign]=useState(false)
    const [courses,setCourses]=useState([])
    const actionClick=(arg)=>{
        if(arg==='add'){
            setAdd(true);setView(false);setAssign(false)
        } else if(arg==='view'){
            setAdd(false);setView(true);setAssign(false)
            getCourses()
        } else {
            setAdd(false);setView(false);setAssign(true)
        }
    }
    const getCourses=async()=>{
        const sql='SELECT c.SrNo,c.CourseName,c.CourseBanner,COUNT(ch.CourseId) AS ChapterCount FROM Courses c LEFT JOIN course_chapters ch ON c.SrNo = ch.courseid GROUP BY c.SrNo, c.CourseName, c.CourseBanner;'
        const res = await fetch('https://api.leadsoftbackend.com/api/getData/'+sql)
        const data = await res.json()
        console.log(data)
        if(data.status){
            setCourses(data.message)
        }
        console.log(courses)
    }
    function AddCourse(){
        const [courseId,setCourseId]=useState(0)
        const [excelData,setExcelData]=useState([])
        const [courseName,setCourseName]=useState('')
        const [courseOverview,setCourseOverview]=useState('')
        const [courseBanner, setCourseBanner] = useState(null);
        const courseFilRef = useRef(null)
        const fileInputRef = useRef(null);
        const excelHandle=(e)=>{
            const file = e.target.files[0]
            const reader = new FileReader()
            
            reader.onload=(event)=>{
                const binaryStr = event.target.result
                const workBook = XLSX.read(binaryStr,{type:'binary'})
                const sheetName = workBook.SheetNames[0]
                const sheet = workBook.Sheets[sheetName]
                const jsonData = XLSX.utils.sheet_to_json(sheet,{header:1})
                setExcelData(jsonData)
            }

            reader.readAsBinaryString(file)
        }
        const getCourseId=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getMaxSrNo/SELECT MAX(SrNo) AS ID FROM courses')
            const data = await res.json()
            if(data.status){
                if(data.message===null){
                    setCourseId(1)
                } else {
                    setCourseId(data.message+1)
                }
            }
        }
        useEffect(()=>{
            getCourseId()
        },[])
        const onCourseSubmit=async(e)=>{
            
            e.preventDefault()
            if(courseName===''||courseOverview==='' ||courseBanner===''){
                toast.error('Enter details !!')
                return
            } 
            console.log('here')
            setLoading(true)
            const formData = new FormData();
            formData.append('courseBanner', courseBanner);
            formData.append('courseOverview', courseOverview);
            formData.append('courseName', courseName);

            const res = await fetch('https://api.leadsoftbackend.com/api/addCourse', {
                method: 'POST',
                body: formData,
            });
            
            const data = await res.json()
            let flag=true;
            for(let i=1;i<excelData.length;i++){
                let videoId = excelData[i][1]
                let title=excelData[i][2]
                if(videoId===null || title ===null){
                    continue
                }
                const res1 = await fetch('https://api.leadsoftbackend.com/api/addCourseChapter', {  // Updated endpoint URL
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        courseId: courseId,
                        videoId: videoId,
                        title: title,
                    }),
                });
                const data1 = await res1.json()
                if(!data1.status){
                    flag=false
                    break
                }
            }
            setLoading(false);
            if(flag){
                if(data.status){
                    toast.success(data.message)
                    setCourseBanner('');setCourseName('');setCourseOverview('')
                    getCourseId()
                } 
            } else {
                toast.error('Internal server error')
            }
        }
        const handleCourseBanner=(e)=>{
            const file = e.target.files[0]
            if(file){
                setCourseBanner(file)
            }
        }
        return(
            <>
                <div className='container1'>
                    <div className='title'>
                        <h2>{actionStatus}</h2>
                    </div>
                    <form onSubmit={onCourseSubmit}>
                        <div className='input-field'>
                            <label>Course Id</label>
                            <input type='number' value={courseId} disabled/>
                        </div>
                        <div className='input-field'>
                            <label>Course Name</label>
                            <input type='text' value={courseName} onChange={(e)=>setCourseName(e.target.value)}/>
                        </div>
                        <div className='input-field'>
                            <label>Browse Course Banner</label>
                            <input type='file' aaccept="image/*"  ref={fileInputRef} onChange={handleCourseBanner}/>
                        </div>
                        <div className='input-field1'>
                            <label>Course Name</label>
                            <textarea value={courseOverview} onChange={(e)=>setCourseOverview(e.target.value)}/>
                        </div>
                        <div className='input-field'>
                            <label>Browse Chapters File</label>
                            <input type='file' accept='.xlsx,.xls' onChange={excelHandle}/>
                        </div>
                        <div className='actions'>
                            <input type='submit' value={actionStatus}/>
                            <input type='reset' value='Cancel'/>
                        </div>
                    </form>
                </div>
            </>
        )
    }

    function ViewCourse({courses}){        
        const itemPerPage=6

        const [currentPage,setCurrentPage]=useState(1)
        const totalPages = Math.ceil(courses.length/itemPerPage)
        const currentPageData = courses.slice((currentPage-1)*itemPerPage,currentPage*itemPerPage)
       
       
        
        const deleteCourses=async(id)=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/deleteQuery/',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    sql:'DELETE FROM courses WHERE SrNo=?',
                    id:id
                })
            })
            const data = await res.json()
            if(data.status){
                if(data.message==='deleted'){
                    toast.success('Course deleted !!')
                    getCourses()                    
                }                
            } else {
                toast.error(data.message)
            }

        }
        return(
            <>
                <div className='container1'>
                    <div className='title'>
                        <h2>View Batches</h2>
                    </div>
                    
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Course Name</th>
                                <th>Course Banner</th>   
                                <th>No Of Chapters</th>                            
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentPageData.length > 0 ? (currentPageData.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{item.SrNo}</td>
                                            <td>{item.CourseName}</td>
                                            <td>
                                                <img src={'https://api.leadsoftbackend.com/uploads/courses/'+item.CourseBanner} />
                                            </td>
                                            <td>{item.ChapterCount}</td>
                                            <td className="table-action">                                                
                                                <button onClick={()=>deleteCourses(item.SrNo)}><i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })) : (
                                    <tr>
                                        <td colSpan="4">No course available</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <div className="pagination">
                        <p>{currentPage} of {totalPages}</p>
                        <div className="buttons">
                        {
                            Array.from({length:totalPages},(_,i)=>i+1).map((page,index)=>{
                                return(
                                    <button onClick={()=>setCurrentPage(page)} className={currentPage===page ? 'selectedButton':''} key={index}>{page}</button>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </>
        )   
    }

    function AssignCourse(){
        const [colleges,setColleges]=useState([])
        const [college,setCollege]=useState('')
        const [batch,setBatch]=useState('')
        const [batches,setBatches]=useState([])
        const getAllColleges=async()=>{
            const res = await fetch('https://api.leadsoftbackend.com/api/getData/SELECT * FROM colleges')
            const data = await res.json()
            if(data.status){
                setColleges(data.message)
            }
        }
        const handleBatch=(e)=>{
            setBatch(e.target.value)
        }
        useEffect(()=>{
            getAllColleges()           
        },[])
        useEffect(()=>{
            getAllCourses()
        
        },[])
        const handleCollege=async(e)=>{
            setCollege(e.target.value)
            const res = await fetch("https://api.leadsoftbackend.com/api/getData/SELECT * FROM batches WHERE CollegeName='"+e.target.value+"'")
            const data = await res.json()
            if(data.status){
                setBatches(data.message)
            }
        }   
        let [card,setCard]=useState([])
        const [courses,setCourses]=useState([])
        const deleteBatch=(item,index)=>{

        }
        const getAllCourses=async()=>{
            const res = await fetch("https://api.leadsoftbackend.com/api/getData/SELECT * FROM courses")
            const data = await res.json()
            if(data.status){
                setCourses(data.message)                
            }
        }
        const [course,setCourse]=useState('')
        const addCourse=async(e)=>{
            e.preventDefault()
            if(course===''||batch===''||college===''){
                toast.error('Select details !!')
                return
            }
            setLoading(true)
            const res = await fetch('https://api.leadsoftbackend.com/api/assignCourse',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    course:course,
                    batch:batch,
                    college:college
                })
            })
            const data = await res.json()
            setLoading(false)
            if(data.status){
                toast.success(data.message)
                setCollege('')
                setCourse('')
                setBatch('')
            } else {
                toast.error(data.message)
            }
            
        }
        return(
            <>
                
                <div className='container1'>
                    <div className='title'>
                        <h2>Assign Courses</h2>
                    </div>
                    <form onSubmit={addCourse}>
                        <div className='input-field' style={{'width':'40%'}}>
                            <label>Course Name</label>
                            <select onChange={(e)=>setCourse(e.target.value)} name='course' value={course}>
                                <option value=''>SELECT COURSE NAME</option>
                                {
                                    courses.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.CourseName}>{item.CourseName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div> 
                        <div className='input-field' style={{'width':'40%'}}>
                            <label>College Name</label>
                            <select onChange={handleCollege} name='college' value={college}>
                                <option value=''>SELECT COLLEGE NAME</option>
                                {
                                    colleges.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.AliasName}>{item.AliasName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='input-field' style={{'width':'40%'}}>
                            <label>Batch Name</label>
                            <select onChange={handleBatch} name='batch' value={batch}>
                                <option value=''>SELECT BATCH NAME</option>
                                {
                                    batches.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.BatchName}>{item.BatchName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>                                
                        <div className='actions' style={{'marginTop':'10px'}}>
                            <input type='submit' value='Add Course'/>
                            <input type='reset' value='Cancel'/>
                        </div>
                    </form>
                </div>
                
            </>
        )
    }

    return(
        <>
            { isLoading && <WaitingPanel/>}
            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='panel'>
                <div className='header'>
                    <button className={add ? 'activeButton':''} onClick={()=>actionClick('add')}>{actionStatus}</button>
                    <button className={view ? 'activeButton':''} onClick={()=>actionClick('view')}>View Course</button>
                    <button className={assign ? 'activeButton':''} onClick={()=>actionClick('assign')}>Assign Courses</button>
                </div>
                <div className='panel2'>
                    { add && <AddCourse/> }
                    { view && <ViewCourse courses={courses}/> }
                    { assign && <AssignCourse/> }
                </div>
            </div>
        </>
    )
}